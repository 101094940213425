/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setDataInputParameters,
  setFluxData,
  setFormattedFluxData,
  importJsonData,
  resetAll,
  setResultsView,
} from "redux/actions";
import { pageTypeEnum } from "redux/reducers";

import {
  Card,
  Form,
  Select,
  InputNumber,
  Button,
  Divider,
  Tooltip,
  Row,
  Col,
  Modal,
  Alert,
  Upload,
  message,
} from "antd";
import {
  InfoCircleOutlined,
  UploadOutlined,
  RightCircleFilled,
} from "@ant-design/icons";
import ReactDataSheet from "react-datasheet";
import { toast } from "react-toastify";
// import { getBaseRequestConfig } from "redux/base-request-config";

const { Option } = Select;

export const getFluxData = (numOfConditions, measuredDataRows, inputData) => {
  let newFluxData = {};
  let conditionsTables = [];
  let measurementTables = [];

  // Measurement headers
  let measurementHeaders = [];
  for (
    let i = 1;
    i <= numOfConditions;
    i++ // let i = fluxDataLength + 1; // i <= // (fluxDataLength === 0 //   ? numOfConditions //   : numOfNewConditions + fluxDataLength); // i++
  ) {
    measurementHeaders.push(
      { readOnly: true, value: "Hours" },
      { readOnly: true, value: "Flux" }
    );
  }

  // Measurement values
  for (let i = 0; i < measuredDataRows; i++) {
    let numOfMeasurementSets = [];
    for (let j = 0; j < numOfConditions; j++) {
      if (inputData) {
        numOfMeasurementSets.push(
          { value: inputData[j].measuredData.hours[i] },
          { value: inputData[j].measuredData.flux[i]?.toFixed(4) }
        );
      } else {
        numOfMeasurementSets.push({ value: "" }, { value: "" });
      }
    }
    measurementTables.push(numOfMeasurementSets);
  }

  // Condition Headers
  let conditionHeaders = [];
  for (let i = 1; i <= numOfConditions; i++) {
    conditionHeaders.push({ readOnly: true, value: `Condition ${i}` });
  }

  // Condition values
  for (let i = 0; i < 4; i++) {
    let numOfConditionSets = [];
    for (let j = 0; j < numOfConditions; j++) {
      if (inputData) {
        const conditionValues = Object.values(inputData[j].testData);
        numOfConditionSets.push({ value: conditionValues[i] });
      } else {
        numOfConditionSets.push({ value: "" });
      }
    }
    conditionsTables.push(numOfConditionSets);
  }

  const newTemperatureGridItem = [conditionHeaders, ...conditionsTables];
  const newMeasuredDataItem = [measurementHeaders, ...measurementTables];

  newFluxData = {
    conditionTable: newTemperatureGridItem,
    measurementTable: newMeasuredDataItem,
  };
  return newFluxData;
};

const Calculator = () => {
  const dispatch = useDispatch();
  const numOfConditions = 16;

  const dataInputParameters = useSelector(
    (state) => state.dataReducers.dataInputParameters
  );
  const fluxData = useSelector((state) => state.dataReducers.fluxData);

  const { dataType, measuredDataRows } = dataInputParameters || {};

  const [generateTableWarning, setGenerateTableWarning] = useState(false);
  const [generateClearTableWarning, setGenerateClearTableWarning] =
    useState(false);
  const [saveValues, setSaveValues] = useState(null);
  // const [showExcelAlert, setShowExcelAlert] = useState(true);
  // const [skipInterpolation, setSkipInterpolation] = useState(false);
  const [normalizeValues, setNormalizeValues] = useState(false);
  const [okTOnormalizeValues, setOkTOnormalizeValues] = useState(false);

  useEffect(() => {
    if (!fluxData) {
      const newFluxData = getFluxData(numOfConditions, measuredDataRows);
      dispatch(setFluxData(newFluxData));
    }
  }, [dispatch, measuredDataRows, numOfConditions, fluxData]);

  useEffect(() => {
    if (okTOnormalizeValues) {
      goToInterpolation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [okTOnormalizeValues]);

  const submitDataInputParameters = (values) => {
    if (fluxData && !generateTableWarning) {
      setGenerateTableWarning(true);
      setSaveValues(values);
    } else {
      const { measuredDataRows } = values || {};
      // const numOfNewConditions = Math.abs(
      //   dataInputParameters.numOfConditions - numOfConditions
      // );
      dispatch(resetAll()); // Resets everything
      dispatch(setDataInputParameters(values));

      const newFluxData = getFluxData(numOfConditions, measuredDataRows);
      dispatch(setFluxData(newFluxData));
    }
  };

  const handleOnPasteConditions = (changes) => {
    const newChanges = changes
      .split(/\r\n|\n|\r/)
      .filter((row) => row.length > 0)
      .map((row) => {
        row = row.split("\t");
        row = row.map((item) => item.replace(",", ""));
        return row;
      });
    if (newChanges?.length > 4 || newChanges[0]?.length > numOfConditions) {
      toast.warn(
        <div>
          <h3>Data Cropped</h3>
          <p>
            Paste detected more data than generated table. Make sure to generate
            a table that fits your data.
          </p>
        </div>,
        { autoClose: false }
      );
    }
    return newChanges;
  };

  const handleOnPasteMeasured = (changes) => {
    let newChanges = changes
      .split(/\r\n|\n|\r/)
      .filter((row) => row.length > 0)
      .map((row) => row.split("\t"));

    // Data cropped error - when pasted data is more than generated table
    if (
      newChanges?.length > measuredDataRows ||
      newChanges[0]?.length > numOfConditions * 2
    ) {
      toast.warn(
        <div>
          <h3>Data Cropped</h3>
          <p>
            Paste detected more data than generated table. Make sure to generate
            a table that fits your data.
          </p>
        </div>,
        { autoClose: false }
      );
    }
    return newChanges;
  };

  const handleConditionTableChange = (changes) => {
    let grid = fluxData.conditionTable.map((row) => [...row]);

    changes.forEach(({ cell, row, col, value }) => {
      const parsedValue = value.replace(",", "");
      grid[row][col] = {
        ...grid[row][col],
        value: parsedValue,
      };
    });

    let foundEmptyArray = [];
    grid.forEach((gridItem) => {
      gridItem.forEach((item) => {
        if (item.value === "") {
          foundEmptyArray.push(true);
        }
        foundEmptyArray.push(false);
      });
    });

    const newFluxData = { ...fluxData, conditionTable: grid };
    dispatch(setFluxData(newFluxData));
  };

  const handleMeasurementTableChange = (changes) => {
    let grid = fluxData.measurementTable.map((row) => [...row]);
    let normalizedValues = false;
    changes.forEach(({ cell, row, col, value }) => {
      if (value.indexOf("%") !== -1) {
        value = value.replace("%", "");
        value = parseInt(value);
        value = (value / 100).toFixed(4);
        normalizedValues = true;
      } else if (col % 2 !== 0 && parseInt(value) > 2) {
        value = parseInt(value);
        value = (value / 100).toFixed(4);
        normalizedValues = true;
      } else if (parseFloat(value) === 1 && parseFloat(value) % 1 === 0) {
        value = Math.round(parseInt(value));
      }
      grid[row][col] = {
        ...grid[row][col],
        value,
      };
    });
    if (normalizedValues) {
      toast.warn(
        <div>
          <h3>Data Normalized</h3>
          <p>Your data has been renormalized</p>
        </div>,
        { autoClose: false }
      );
    }
    const newFluxData = { ...fluxData, measurementTable: grid };
    dispatch(setFluxData(newFluxData));
  };
  // const performCalculation = (data, section) => {
  //   data.forEach((values, parentIndex) => {
  //     // Handles 0 hour values which are greater than 1
  //     // console.log('values length', values.length);
  //     values.forEach((value, index) => {

  //       if (index >= section - 1 && !(index > section)) {
  //         // console.log('newSection', newSection);
  //         // newSection = index + 1;
  //         if (convertMeasuredDataForNormalizing.length >= parentIndex + 1) {
  //           if (value !== "") {
  //             if (parentIndex === 0) {
  //               if (index % 2 === 0) {
  //                 if (value !== "0") {
  //                   foundNonZeroFirstHourValue = true; // sets of the warning, if any condition is missing a 0-hour value
  //                 }
  //               }
  //             }

  //             if (index % 2 === 1 && value !== 1) {
  //               if (values[index - 1] === "0") {
  //                 foundDataToNormalize = true;
  //                 // values[index] = 1;
  //               }
  //             }
  //           }

  //           // Handles the remaining hour values
  //           if (index % 2 === 1 && value !== 1.0 && value !== "") {
  //             if (values[index - 1].replace(/\s+/g, '') === "0") {
  //               // console.log('values of 0', values[index]);
  //               zeroHourValue = values[index];
  //               values[index] = 1;
  //             } else {
  //               // Normalizing depends on the 0 hour value - if hours start at 0 divide by 0-hour flux value if not just divide by 100
  //               // console.log('parsed Float', parseFloat(measurementTable[1][index].value));
  //               // console.log('ZeroHourValue', zeroHourValue);
  //               let divideBy =
  //                 values[index - 1].replace(/\s+/g, '') === "0"
  //                   ? parseFloat(measurementTable[1][index].value)
  //                   : parseFloat(zeroHourValue);
  //               // console.log('toFixedFour', divideBy.toFixed(4));
  //               values[index] = divideBy ? (value / divideBy.toFixed(4)).toFixed(4) : value;
  //             }
  //           }
  //         }
  //       }
  //     });
  //   });
  // }
  const goToInterpolation = () => {
    let formattedFluxData = {};
    const { conditionTable, measurementTable } = fluxData;
    let tempMeasurementData = measurementTable;

    let foundDataToNormalize = false;
    let convertMeasuredDataForNormalizing = [];
    let processedData = [];
    measurementTable.forEach((data, index) => {
      if (index >= 1) {
        let Obj = [];
        data.forEach((item, i) => {
          let hour;
          if (i % 2 === 0 && data[i + 1].value !== '') {
            Obj.push({ [item.value]: data[i + 1].value });
          }
        })

        processedData.push(Obj);
      }
    });
    console.log(processedData);
    let zeroHourValues = processedData[0];

    measurementTable.forEach((data, index) => {
      if (index >= 1) {
        let newColumn = data.map((value) => value.value);
        convertMeasuredDataForNormalizing.push(newColumn);
      }
    });

    let foundNonZeroFirstHourValue = false;
    let zeroHourValue;

    convertMeasuredDataForNormalizing.forEach((values, parentIndex) => {
      // Handles 0 hour values which are greater than 1
      // console.log('values length', values.length);
      // if (convertMeasuredDataForNormalizing.length >= parentIndex + 1 && oldSection === newSection) {

      values.forEach((value, index) => {

        // if (index = newSection - 1 && !(index > newSection)) {
        //   console.log('newSection', newSection);
        // newSection = index + 1;
        // if (convertMeasuredDataForNormalizing.length >= parentIndex + 1) {
        if (value !== "") {
          if (parentIndex === 0) {
            if (index % 2 === 0) {
              if (value !== "0") {
                foundNonZeroFirstHourValue = true; // sets of the warning, if any condition is missing a 0-hour value
              }
            }
          }

          if (index % 2 === 1 && value !== 1) {
            if (values[index - 1] === "0") {
              foundDataToNormalize = true;
              // values[index] = 1;
            }
          }
        }

        // Handles the remaining hour values
        if (index % 2 === 1 && value !== 1.0 && value !== "") {
          if (values[index - 1].replace(/\s+/g, '') === "0") {
            // console.log('values of 0', values[index]);
            // const zeroHourIndex = (index + 1) / 2;
            // zeroHourValue = zeroHourValues[zeroHourIndex - 1][0];
            values[index] = 1;
          } else {
            // Normalizing depends on the 0 hour value - if hours start at 0 divide by 0-hour flux value if not just divide by 100
            // console.log('parsed Float', parseFloat(measurementTable[1][index].value));
            // console.log('ZeroHourValue', zeroHourValue);
            const zeroHourIndex = (index + 1) / 2;
            zeroHourValue = zeroHourValues[zeroHourIndex - 1][0];
            let divideBy =
              values[index - 1].replace(/\s+/g, '') === "0"
                ? parseFloat(measurementTable[1][index].value)
                : parseFloat(zeroHourValue);
            // console.log('toFixedFour', divideBy.toFixed(4));
            values[index] = divideBy ? (value / divideBy.toFixed(4)).toFixed(4) : value;
          }
        }

      });

    });

    if (foundNonZeroFirstHourValue) {
      toast.warn(
        <div>
          <h3>Warning!</h3>
          <p>
            Because the entered data does not begin at zero, the application
            cannot confirm that it is appropriated scaled with a 0-hour value of
            1. Please ensure your data follows IES LM-80 guidance.
          </p>
        </div>,
        { autoClose: false, toastId: "doesNotBeginAtZero" }
      );
    }

    if (okTOnormalizeValues) {
      // toast.warn(
      //   <div>
      //     <h3>Data Normalized</h3>
      //     <p>Your data has been renormalized to a value of 1 at 0 hours.</p>
      //   </div>,
      //   { autoClose: false }
      // );

      let normalizedMeasuredData = [measurementTable[0]];
      convertMeasuredDataForNormalizing.forEach((data) => {
        let backConvertedMeasuredData = data.map((value) => {
          return { value: value !== "" ? value : "" };
        });
        normalizedMeasuredData.push(backConvertedMeasuredData);
      });
      tempMeasurementData = normalizedMeasuredData;
      const newFluxData = {
        ...fluxData,
        measurementTable: tempMeasurementData,
      };
      setOkTOnormalizeValues(false);
      dispatch(setFluxData(newFluxData));
    }
    else if (foundDataToNormalize) {
      setNormalizeValues(true);
    }

    // let emptyCount = 0;
    // fluxData.measurementTable.forEach((row) => {
    //   if (emptyCount !== numOfConditions * 2 && emptyCount !== 0) {
    //     return;
    //   }
    //   emptyCount = 0;
    //   row.forEach((column) => {
    //     if (column.value === "") {
    //       emptyCount++;
    //     }
    //   });
    // });
    // let disableButton = false;
    // if (emptyCount !== numOfConditions * 2 && emptyCount !== 0) {
    //   disableButton = true;
    //   toast.error(
    //     <div>
    //       <h3>Error</h3>
    //       <p>
    //         Please make sure you have all the data to perform interpolation.
    //       </p>
    //     </div>,
    //     { autoClose: false }
    //   );
    // }
    // if (!disableButton) {

    if (!foundDataToNormalize) {
      for (let i = 0; i < numOfConditions; i++) {
        let conditionTableValues = [];
        // let measurementTableValues = {};
        let hoursArray = [];
        let fluxDataArray = [];
        conditionTable.forEach(
          (item) =>
            !item[i].readOnly && conditionTableValues.push(item[i].value)
        );
        tempMeasurementData.forEach((item) => {
          if (!item[i].readOnly) {
            const hourValue = item[i + i].value;
            const fluxValue = item[i + i + 1].value;
            hourValue !== "" && hoursArray.push(parseInt(hourValue));
            fluxValue !== "" && fluxDataArray.push(parseFloat(fluxValue));
          }
        });

        const validateConditions = conditionTableValues.every(
          (value) => value !== ""
        );

        if (
          validateConditions &&
          hoursArray.length > 0 &&
          fluxDataArray.length > 0
        ) {
          let formattedFluxItem = {
            [`condition${i + 1}`]: {
              testData: {
                caseTemperature: parseFloat(conditionTableValues[0]),
                driveCurrent: parseInt(conditionTableValues[1]),
                numberOfUnitsTested: parseInt(conditionTableValues[2]),
                numberOfFailures: parseInt(conditionTableValues[3]),
              },
              measuredData: {
                hours: hoursArray,
                flux: fluxDataArray,
              },
            },
          };
          Object.assign(formattedFluxData, formattedFluxItem);
        }
      }

      if (Object.keys(formattedFluxData).length === 0) {
        toast.error(
          <div>
            <h3>Error</h3>
            <p>Invalid data input. Please make sure you have data in condition column and Hours | Flux Column</p>
          </div>,
          { autoClose: false }
        );
      } else {
        dispatch(setFormattedFluxData(formattedFluxData));

        // if (skipInterpolation) {
        //   const inSituTemperature =
        //     formattedFluxData.condition1.testData.caseTemperature;
        //   const inSituCurrent =
        //     formattedFluxData.condition1.testData.driveCurrent;
        //   const interpolationParameters = { inSituTemperature, inSituCurrent };

        //   const interpolationData = {
        //     interpolationParameters: {
        //       inSituTemperature: parseFloat(inSituTemperature),
        //       inSituCurrent: parseFloat(inSituCurrent),
        //       fluxType: "Luminous",
        //     },
        //   };

        //   dispatch(setInterpolationParameters(interpolationParameters));
        //   dispatch(
        //     calculateInterpolation(
        //       formattedFluxData,
        //       interpolationData,
        //       {},
        //       "interpolation"
        //     )
        //   );
        //   dispatch(setResultsView(pageTypeEnum.Report));
        // } else {
        dispatch(setResultsView(pageTypeEnum.Report));
        // }
      }
    }
  };

  const jsonUploadProps = {
    name: "file",
    action: null,
    accept: ".json",
    showUploadList: false,
    beforeUpload: (file) => {
      if (file.type !== "application/json") {
        message.error(`${file.name} is not a JSON file`);
      }
    },
    onChange: async (info) => {
      var fileReader = new FileReader();
      fileReader.readAsText(info.file.originFileObj);
      fileReader.onload = (event) => {
        const savedJsonData = JSON.parse(event.target.result);
        if (
          JSON.stringify(Object.keys(savedJsonData)) ===
          JSON.stringify(["input", "output"])
        ) {
          dispatch(importJsonData(savedJsonData));
          toast.success(
            <div>
              <h3>Success</h3>
              <p>Successfully uploaded JSON file!</p>
            </div>,
            {
              toastId: "json-upload-success",
            }
          );
        } else {
          message.error({
            content: "Invalid file format",
            key: "invalid-json-error",
          });
        }
      };
    },
  };
  const updateNormalizeValues = () => {
    setNormalizeValues(false);
    setOkTOnormalizeValues(true);
    // goToInterpolation();
  }
  return (
    <>
      <Modal
        title="Confirm Normalization"
        visible={normalizeValues}
        onCancel={() => setNormalizeValues(false)}
        footer={[
          <Button onClick={() => setNormalizeValues(false)}>
            No, I will edit the data myself
          </Button>,
          <Button
            type="primary"
            onClick={updateNormalizeValues}
          >
            Normalize it and let me review
          </Button>,
          // <Button
          //   type="primary"
          //   onClick={() => {
          //     setNormalizeValues(false);
          //     setOkTOnormalizeValues(true);
          //     goToInterpolation();
          //   }}
          // >
          //   Yes, and proceed to interpolation
          // </Button>,
        ]}
      >
        You have entered a 0 hour value that is not 1, as required by LM-80. You can edit the data yourself, or the data can be automatically normalized so the 0 hour value is 1 (you will have a chance to review).
      </Modal>
      <Modal
        title="Confirm new table"
        visible={generateTableWarning}
        onCancel={() => setGenerateTableWarning(false)}
        onOk={() => {
          submitDataInputParameters(saveValues);
          setGenerateTableWarning(false);
        }}
      >
        Are you sure you want to generate a new table? All your data will be
        lost.
      </Modal>
      <Modal
        title="Confirm clear table"
        visible={generateClearTableWarning}
        onCancel={() => setGenerateClearTableWarning(false)}
        onOk={() => {
          dispatch(setFluxData(null));
          dispatch(setFormattedFluxData(null));
          setGenerateClearTableWarning(false);
        }}
      >
        Are you sure you want to clear the table? All your input data will be
        lost.
      </Modal>
      <Card>
        <div css={fluxDataCardHeaderStyles}>
          {/* <h3>{`ANSI/IES LM-80 ${dataType} Data`} </h3> */}
          <h3>Enter ANSI/IES LM-80 Data</h3>
          <div>
            {/* {numOfConditions === 1 && (
              <Checkbox
                onChange={() => setSkipInterpolation(!skipInterpolation)}
              >
                Skip Interpolation
              </Checkbox>
            )} */}
            {/* <Tooltip
              placement="top"
              title="Make sure to enter flux data to proceed with interpolation"
              trigger={
                !(fillingConditions && fillingMeasuredData) ? ["hover"] : []
              }
            > */}
            <div css={GoToButtonStyles} onClick={goToInterpolation}>
              <span className="nav-text">Calculate and go to reports</span>
              <RightCircleFilled />
            </div>
            {/* </Tooltip> */}
          </div>
        </div>
        <br />
        <Form
          css={formStyles}
          // {...layout}
          layout="inline"
          initialValues={{ dataType, numOfConditions, measuredDataRows }}
          onFinish={submitDataInputParameters}
        >
          <Form.Item
            // hasFeedback
            // tooltip="Data Type"
            label="Data type"
            name="dataType"
            rules={[
              {
                required: true,
                message: "Please input data type",
              },
            ]}
          // validateStatus={dataType.validationStatus}
          // help={dataType.errorMsg}
          >
            <Select
              style={{ width: 240 }}
              // defaultValue={dataInputParameters.dataType}
              onChange={(value) =>
                dispatch(
                  setDataInputParameters({
                    ...dataInputParameters,
                    dataType: value,
                  })
                )
              }
            >
              <Option value="L">Luminous Flux</Option>
              <Option value="R">Radiant Flux</Option>
              <Option value="Q">Photon Flux</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item
            // hasFeedback
            tooltip="A test condition is a combination of case temperature and drive current. For example, 350 mA at 55 C is one condition."
            label="Number of conditions"
            name="numOfConditions"
            rules={[
              {
                required: true,
                message: "Please input number of conditions",
              },
            ]}
            // validateStatus={numOfConditions.validationStatus}
            // help={numOfConditions.errorMsg}
          >
            <InputNumber style={{ width: 240 }} min={1} max={20} />
          </Form.Item> */}
          <Form.Item
            // hasFeedback
            // tooltip=""
            label="Measured data rows"
            name="measuredDataRows"
            rules={[
              {
                required: true,
                message: "Please input measured data rows",
              },
            ]}
          // validateStatus={numOfConditions.validationStatus}
          // help={numOfConditions.errorMsg}
          >
            <InputNumber style={{ width: 240 }} min={6} max={100} />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: "1rem" }}
              type="primary"
              htmlType="submit"
            >
              Generate New Table
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => setGenerateClearTableWarning(true)}
            >
              Clear Table
            </Button>
            <Tooltip
              placement="top"
              title="Input data that was previously saved from this application."
            >
              <Upload {...jsonUploadProps}>
                <Button icon={<UploadOutlined />}>Upload saved JSON</Button>
              </Upload>
            </Tooltip>
          </Form.Item>
        </Form>
        {/* <Alert
          style={{ fontWeight: "bold" }}
          message="Data must be entered as decimals."
          type="warning"
          showIcon
        />
        <br /> */}
        <Alert
          message="When pasting from Excel, only visible data will copy, not
              the full precision value. Increase the number of visible decimal
              places in Excel to ensure accurate calculations."
          type="info"
          showIcon
        />
        <Divider />
        {fluxData !== null ? (
          <Row>
            <Col span={3}>
              <ul css={conditionsTableLabelsStyles}>
                <li>
                  <Tooltip
                    placement="right"
                    title="LM-80 test data will utilize the same nominal case temperature at different currents.  For LM-80 data sets that have slight variations in case temperature, the average value of the case temperature rounded to the nearest 0.1°C will be used."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </li>
                <li>Case temperature (°C):</li>
                <li>Drive Current (mA):</li>
                <li>Number of units tested:</li>
                <li>Number of failures:</li>
                <li className="margin-top">
                  <Tooltip
                    placement="right"
                    title="Data must be entered in decimal format. If not, the data entered should be normalized so the flux at hours = 0 equals 1 before averaging. If hours = 0 is normalized to 100, the application will automatically scale the data."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </li>
                <li>Measured data:</li>
                <li>
                  <b>(decimal format)</b>
                </li>
              </ul>
            </Col>
            {/* <Col span={1}></Col> */}
            <Col span={21}>
              <div css={conditionsTablesContainerStyles}>
                {fluxData !== null && (
                  <div css={tablesContainerStyles}>
                    <ReactDataSheet
                      key="conditions-table"
                      css={[reactDataSheetStyles, conditionsTable]}
                      data={fluxData.conditionTable}
                      valueRenderer={(cell) => cell.value}
                      onCellsChanged={(changes) =>
                        handleConditionTableChange(changes)
                      }
                      parsePaste={(changes) => handleOnPasteConditions(changes)}
                    />
                    <ReactDataSheet
                      key="measurements-table"
                      css={[reactDataSheetStyles, measurementTable]}
                      data={fluxData.measurementTable}
                      valueRenderer={(cell) => cell.value}
                      onCellsChanged={(changes) =>
                        handleMeasurementTableChange(changes)
                      }
                      parsePaste={(changes) => handleOnPasteMeasured(changes)}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <p css={emptyFluxDataContainerStyles}>
            Please enter information and hit generate table to create data
            sheets for flux data input.
          </p>
        )}
      </Card>
    </>
  );
};

export default Calculator;

const fluxDataCardHeaderStyles = css`
  display: flex;
  justify-content: space-between;
`;

const conditionsTablesContainerStyles = css`
  .data-grid-container .data-grid {
    border: 2px solid #b2b2b2;
  }

  display: flex;
  overflow-x: auto;
  max-width: 100%;
  @media (max-width: 1662px) {
  }
`;

const tablesContainerStyles = css`
  margin: 0 1rem;

  @media (max-width: 1480px) {
    margin-left: 4rem;
  }

  @media (max-width: 1120px) {
    margin-left: 6rem;
  }
`;

export const reactDataSheetStyles = css`
  .data-editor {
    width: 100% !important;
    height: 100% !important;
  }

  .cell {
    height: 23px !important;
    width: 2rem !important;
    .value-viewer {
      text-align: center;
      color: black;
    }
  }
`;

export const conditionsTable = css`
  margin-bottom: 1rem;
  td {
    min-width: 8rem;
    border-right: 2px solid #b2b2b2 !important;
  }

  td:last-of-type {
    border-right: 1px solid #ddd !important;
  }
`;

export const measurementTable = css`
  td {
    min-width: 4rem;
  }

  td:nth-of-type(even) {
    border-right: 2px solid #b2b2b2 !important;
  }

  td:last-of-type {
    border-right: 1px solid #ddd !important;
  }
`;

export const conditionsTableLabelsStyles = css`
  list-style-type: none;
  margin-right: 1rem;
  text-align: end;
  padding: 0;
  position: absolute;
  min-width: 10rem;
  background: white;
  z-index: 1;
  padding-right: 1rem;

  li {
    font-size: 14px;
    margin-bottom: 2px;
    white-space: nowrap;
  }

  .anticon-info-circle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  .margin-top {
    margin-top: 1.5rem;
  }

  li:last-child {
    // margin-top: 2rem;
  }
`;

const emptyFluxDataContainerStyles = css`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 12rem 10rem;
`;

const formStyles = css`
  display: flex;
  flex-wrap: wrap;

  .ant-form-item {
    padding: 0.5rem 0;
  }
`;

const GoToButtonStyles = css`
  cursor: pointer;

  .nav-text {
    vertical-align: 7px;
    font-size: 16px;

    :hover {
      text-decoration: underline;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
    color: #d6781e;
    margin-left: 0.5rem;
  }
`;
