import { call, put, takeEvery } from "redux-saga/effects";

import { CALCULATE_INTERPOLATION } from "./types";
import api from "./api";

import { message } from "antd";
import { toast } from "react-toastify";

function* calculateInterpolation(action) {
  const { fluxData, interpolationData, reportedValues, calculationType } =
    action.payload;
  try {
    const response = yield call(
      api.calculateInterpolation,
      fluxData,
      interpolationData,
      reportedValues,
      calculationType
    );
    if (response.error) {
      toast.error(
        <div>
          <h3>Error</h3> <p>{response.error}</p>
        </div>,
        {
          toastId: "interpolation-error",
        }
      );
      yield put({
        type: CALCULATE_INTERPOLATION.FAILED,
        payload: { type: "calculation", message: response.error },
      });
    } else {
      yield put({ type: CALCULATE_INTERPOLATION.SUCCEEDED, payload: response });
      message.success("Successfully updated reports");
    }
  } catch (e) {
    toast.error(
      <div>
        <h3>Error</h3> <p>Something went wrong!</p>
      </div>,
      {
        toastId: "interpolation-error",
      }
    );
    yield put({
      type: CALCULATE_INTERPOLATION.FAILED,
      payload: { type: "network", message: "Something went wrong!" },
    });
  }
}

function* tm21Saga() {
  yield takeEvery(CALCULATE_INTERPOLATION.REQUESTED, calculateInterpolation);
}

export default tm21Saga;
