/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import html2canvas from "html2canvas";
import moment from "moment";
// import * as htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";

import { setInterpolationChanged, clearPages } from "redux/actions";
import { pageTypeEnum } from "redux/reducers";

import { LoadingScreen } from "components/loadingScreen";
import ReportTable1 from "components/reportTable1";
import ReportTable2 from "components/reportTable2";
import ReportPlot from "components/reportPlot";
import ReportInputData from "components/reportInputData";
import ParametersForm from "components/parametersForm";
import { WorkSpace } from "pages/interpolation";
import Calculator from "pages/calculator";

import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Select,
  Divider,
  Pagination,
  Modal,
  Tooltip,
} from "antd";

const { Option } = Select;

const Report = () => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.dataReducers);

  const {
    fluxData,
    dataInputParameters,
    interpolationParameters,
    reportedValues,
    reportMetadata,
    reportHeaderInfo,
    formattedFluxData,
    interpolationPlotData,
    interpolationRawOutput,
    interpolationTableData,
    reportTable1Data,
    reportTable2Data,
    interpolationChanged,
    interpolationError,
    pageType,
  } = state;

  const { inSituTemperature, inSituCurrent } = interpolationParameters || {};

  const {
    fluxMaintenanceLevel,
    projectedFluxMaintenance,
    ratedMaxCurrent,
    nominalForwardVoltage,
  } = reportedValues || {};

  const { description, report, company, date, notes } = reportMetadata || {};

  const { ProjFluxMaintenance, fluxMaintenance } = reportHeaderInfo || {};

  const [reportPage, setReportPage] = useState(1);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [reportDownload, setReportDownload] = useState(false);
  const [jsonDownloadModal, setJsonDownloadModal] = useState(false);
  const [clearReportPageModal, setClearReportPageModal] = useState(false);
  const [jsonFileName, setJsonFileName] = useState("");

  let fluxMaintenanceLevelOptions = [];
  for (let i = 70; i <= 95; i++) {
    fluxMaintenanceLevelOptions.push(
      <Option key={i} value={i}>
        {i}%
      </Option>
    );
  }

  const onReportPageChange = (value) => {
    setReportPage(value);
  };

  const generateImages = () => {
    return new Promise((resolve) => {
      let allPromises = [];
      let image1Promise = html2canvas(
        document.getElementById("report-content-1"),
        {
          scale: 2,
        }
      );
      let image2Promise = html2canvas(
        document.getElementById("report-content-2"),
        {
          scale: 2,
        }
      );
      let image3Promise = html2canvas(
        document.getElementById("report-content-3"),
        {
          scale: 2,
        }
      );
      let image4Promise = html2canvas(
        document.getElementById("report-content-4"),
        {
          scale: 2,
        }
      );
      let imageFooterPromise = html2canvas(
        document.getElementById("footer-content"),
        {
          scale: 2,
        }
      );

      allPromises = [
        image1Promise,
        image2Promise,
        image3Promise,
        image4Promise,
        imageFooterPromise,
      ];
      if (allPromises.length === 5) {
        resolve(allPromises);
      }
    });
  };

  const handlePdfReportDownload = () => {
    setLoadingSpinner(true);
    setReportDownload(true);
    setTimeout(async () => {
      let pdfDimensions = [15, 10, 180, 200]; // x, y, width, height
      let pdf = new jsPDF("p", "mm", "a4", true);
      const imagePromises = await generateImages();
      const resolvedPromises = await Promise.all(imagePromises);
      resolvedPromises.forEach((dataUrl, index) => {
        if (index <= 3) {
          if (index === 0) {
            pdfDimensions = [15, 10, 180, 220];
          }
          if (index === 2) {
            if (reportTable2Data.current.length === 1) {
              pdfDimensions = [15, 10, 180, 120];
            } else {
              pdfDimensions = [15, 10, 180, 160];
            }
          }
          if (index === 3) {
            pdfDimensions = [15, 10, 180, 200];
          }

          pdf.addImage(dataUrl, "PNG", ...pdfDimensions, "", "FAST");
          pdf.addImage(
            resolvedPromises[4],
            "PNG",
            ...[7, 280, 210, 10],
            "",
            "FAST"
          );
          index < 3 && pdf.addPage();
        }
      });
      pdf.save(`ANSI/IES TM-21-21 Report.pdf`);
      setLoadingSpinner(false);
      setReportDownload(false);
    }, 1000);
  };

  let content;
  switch (reportPage) {
    case 1:
      content = <ReportTable1 />;
      break;
    case 2:
      content = <ReportPlot />;
      break;
    case 3:
      content = (
        <div css={page3ContentStyles}>
          <p>Analysis Summary at LM-80 Testing Conditions</p>
          <ReportTable2 />
        </div>
      );
      break;
    case 4:
      content = <ReportInputData />;
      break;
    default:
      break;
  }

  const handleExportToJson = (fileName) => {
    setLoadingSpinner(true);
    const data = {
      input: {
        dataInputParameters,
        fluxData,
        interpolationParameters,
        reportedValues,
      },
      output: {
        formattedFluxData,
        interpolationPlotData,
        interpolationRawOutput,
        interpolationTableData,
        reportTable1Data,
        reportTable2Data,
        reportHeaderInfo,
        reportMetadata,
      },
    };
    const blob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });
    if (fileName !== "") {
      saveAs(blob, `${fileName}.json`);
    } else {
      saveAs(blob, `TM-21.json`);
    }
    setLoadingSpinner(false);
    setJsonDownloadModal(false);
  };
  const handleJsonFileName = (e) => {
    setJsonFileName(e.target.value);
  };

  const reportWrapper = (children, page) => {
    return (
      <div>
        <Modal
          title="Save as JSON"
          visible={jsonDownloadModal}
          onCancel={() => setJsonDownloadModal(false)}
          footer={
            <Button
              type="primary"
              onClick={() => handleExportToJson(jsonFileName)}
            >
              Download
            </Button>
          }
        >
          JSON File Name:
          <Input defaultValue="TM-21" onChange={handleJsonFileName} />
        </Modal>
        <Modal
          title="Clear report page"
          visible={clearReportPageModal}
          onCancel={() => setClearReportPageModal(false)}
          footer={
            <>
              <Button
                onClick={() => {
                  setClearReportPageModal(false);
                }}
              >
                No
              </Button>
              <Button
                type="primary"
                danger
                ghost
                onClick={() => {
                  dispatch(clearPages(false));
                  setClearReportPageModal(false);
                }}
              >
                Yes
              </Button>
            </>
          }
        >
          Are you sure you want to clear the report page?
        </Modal>
        <div css={reportTitleAndContentsStyles}>
          <h1>ANSI/IES TM-21-21 Report</h1>
          <p style={{ display: "flex" }}>
            <span className="bold-text">
              Description of tested LED light source:
            </span>
            <span style={{ marginLeft: "4px" }}>{description}</span>
          </p>
        </div>
        <ul css={reportPropertiesStyles}>
          <li>
            <p className="bold-text">Rated maximum current (DC):</p>{" "}
            {ratedMaxCurrent} mA
          </li>
          <li>
            <p className="bold-text">Nominal Vf:</p> {nominalForwardVoltage} V
          </li>
          <li>
            <p className="bold-text">Maximum input power:</p>{" "}
            {(nominalForwardVoltage * ratedMaxCurrent) / 1000} W
          </li>
          <li>
            <p className="bold-text">Temperature (interpolated):</p>{" "}
            {inSituTemperature} °C
          </li>
          <li>
            <p className="bold-text">In-situ current (interpolated):</p>{" "}
            {inSituCurrent} mA
          </li>
          {/* Adding dummy li's to move Flux Maintenance to next row */}
          <li></li>
          <li></li>
          <li></li>
          <li>
            <p className="bold-text">
              Flux maintenance {dataInputParameters.dataType.charAt(0)}
              {fluxMaintenanceLevel}:
            </p>{" "}
            {fluxMaintenance} hours
          </li>
          {projectedFluxMaintenance && (
            <li>
              <p className="bold-text">
                Flux maintenance at {projectedFluxMaintenance} hours:
              </p>{" "}
              {Math.round(ProjFluxMaintenance * 100)}%
            </li>
          )}
        </ul>
        <Divider />
        <div css={mainContentStyles}>{children}</div>
        <Divider />
        <div css={reportFooterPropertiesStyles}>
          <div className="left-column">
            <div>
              <p className="bold-text">Report generated by:</p>
              <p>{report}</p>
            </div>
            <div>
              <p className="bold-text">Company:</p>
              <p>{company}</p>
            </div>
            <div>
              <p className="bold-text">Date:</p>
              <p>{date}</p>
            </div>
          </div>
          <div>
            <p className="bold-text notes">Notes:</p>
            <p>{notes}</p>
          </div>
        </div>
        {!reportDownload && (
          <div css={bottomFooterTextStyles}>
            <img
              alt="app-logo"
              className="footer-picture"
              src={`${process.env.PUBLIC_URL}/images/ies-logo.jpg`}
            />
            <div className="footer-text">
              The content of this report was generated by Illuminating
              Engineering Society's online TM-21 calculator (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ies.org/tools"
              >
                www.ies.org/tools
              </a>
              ) on {moment().format("L")}, in conformance with the ANSI/IES
              TM-21-21 standard published by IES, 120 Wall Street, New York, New
              York 10005.
            </div>
          </div>
        )}
        {/* <span
          style={{ marginTop: "1rem", display: "block", textAlign: "center" }}
        >
          {moment().format("L")}
        </span> */}
        {/* {page && <span style={{ float: "right" }}>Page {page} of 4</span>} */}
      </div>
    );
  };

  return (
    <>
      {/* {!formattedFluxData && (
        <div css={noDataScreenStyles}>
          {" "}
          Enter flux data and interpolation parameters in the previous pages to
          view report
        </div>
      )} */}

      {reportDownload && (
        <div css={reportDownloadContainer}>
          <div id="report-content-1" style={{ width: "50rem" }}>
            {reportWrapper(<ReportTable1 />, 1)}
          </div>
          <div id="report-content-2" style={{ width: "50rem" }}>
            {reportWrapper(<ReportPlot />, 2)}
          </div>
          <div id="report-content-3" style={{ width: "55rem" }}>
            {reportWrapper(
              <div css={page3ContentStyles}>
                <p>Analysis summary at LM-80 Testing Conditions</p>
                <ReportTable2 />
              </div>,
              3
            )}
          </div>
          <div id="report-content-4" style={{ width: "60rem" }}>
            {reportWrapper(<ReportInputData />, 4)}
          </div>
          <div
            id="footer-content"
            style={{ width: "55rem" }}
            css={bottomFooterTextStyles}
          >
            <img
              alt="app-logo"
              className="footer-picture"
              src={`${process.env.PUBLIC_URL}/images/ies-logo.jpg`}
            />
            <div className="footer-text">
              The content of this report was generated by Illuminating
              Engineering Society's online TM-21 calculator (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ies.org/tools"
              >
                www.ies.org/tools
              </a>
              ) on {moment().format("L")}, in conformance with the ANSI/IES
              TM-21-21 standard published by IES, 120 Wall Street, New York, New
              York 10005.
            </div>
          </div>
        </div>
      )}

      <Modal
        title="Warning"
        closable={false}
        visible={interpolationChanged && pageType === pageTypeEnum.Report}
        footer={
          <Button
            type="primary"
            onClick={() => dispatch(setInterpolationChanged())}
          >
            Ok
          </Button>
        }
      >
        The in-situ temperature and current have been changed to match what was
        entered in the Work Space.
      </Modal>

      {/* <Radio.Group
        css={radioButtonStyles}
        value={pageType}
        onChange={(e) => dispatch(setResultsView(e.target.value))}
      >
        <Radio.Button value={pageTypeEnum.DataInput}>Data Input</Radio.Button>
        <Radio.Button disabled={!formattedFluxData} value={pageTypeEnum.Report}>
          Reports
        </Radio.Button>
        <Radio.Button
          disabled={!formattedFluxData}
          value={pageTypeEnum.WorkSpace}
        >
          Work Space
        </Radio.Button>
      </Radio.Group> */}

      {pageType === pageTypeEnum.DataInput ? (
        <Calculator />
      ) : (
        <Row css={noDataMainContentStyles}>
          <Col className="col-left">
            <ParametersForm />
          </Col>
          <Col span={1}></Col>
          <Col className="col-right">
            {pageType === pageTypeEnum.WorkSpace ? (
              <WorkSpace />
            ) : (
              <Card>
                <div css={loadingScreenStyles(reportDownload)}>
                  <LoadingScreen
                    show={
                      ((!reportTable1Data || !reportTable2Data) &&
                        reportedValues &&
                        !interpolationError) ||
                      loadingSpinner
                    }
                  />
                  {reportDownload && <p>Downloading reports...</p>}
                </div>
                {/* {!reportTable1Data && !reportTable2Data && (
                  <div css={emptyValuesTextStyles}>
                    Enter interpolation parameters and reported values to see
                    results
                  </div>
                )} */}
                <div
                // css={noCalculationsStyles(
                //   ((!reportTable1Data || !reportTable2Data) &&
                //     reportedValues &&
                //     !interpolationError) ||
                //   loadingSpinner ||
                //   (!reportTable1Data && !reportTable2Data),
                //   reportPage
                // )}
                >
                  <div css={reportPageHeaderStyles}>
                    <Pagination
                      size="small"
                      total={40}
                      onChange={onReportPageChange}
                    />
                    <div>
                      <Button
                        style={{ marginRight: "1rem" }}
                        type="primary"
                        onClick={handlePdfReportDownload}
                      >
                        Create PDF (Full Report)
                      </Button>
                      <Tooltip
                        placement="top"
                        title="Save the input data and results to your computer to allow re-upload later."
                      >
                        <Button
                          style={{ marginRight: "1rem" }}
                          className="button"
                          type="primary"
                          onClick={() => setJsonDownloadModal(true)}
                        >
                          Save as JSON
                        </Button>
                      </Tooltip>
                      {reportedValues && reportTable1Data && reportTable2Data && (
                        <Button
                          className="button"
                          type="primary"
                          danger
                          onClick={() => setClearReportPageModal(true)}
                        >
                          Clear
                        </Button>
                      )}
                    </div>
                  </div>
                  <Divider />
                  {reportWrapper(content)}
                </div>
              </Card>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default Report;

// const radioButtonStyles = css`
//   display: block;
//   text-align: center;
//   margin-bottom: 2rem;

//   .ant-radio-button-wrapper {
//     width: 10rem;
//     outline: none;
//     padding: 18px;
//     line-height: 1px;
//     font-size: 16px;
//     font-weight: bold;
//   }

//   .ant-radio-button-wrapper:first-of-type {
//     border-top-left-radius: 2rem;
//     border-bottom-left-radius: 2rem;
//   }

//   .ant-radio-button-wrapper:last-child {
//     border-top-right-radius: 2rem;
//     border-bottom-right-radius: 2rem;
//   }

//   .ant-radio-button-wrapper-checked {
//     background: #d6781e;
//     color: white;

//     :hover {
//       color: white;
//     }
//   }
// `;

// const noDataScreenStyles = css`
//   margin: 10% 25%;
//   position: absolute;
//   z-index: 1;
//   width: 50rem;
//   font-size: 20px;
//   font-weight: bold;
//   color: #d6781e;
// `;

const noDataMainContentStyles = css`
  .col-left {
    @media only screen and (max-width: 1200px) {
      width: 100%;
      margin-bottom: 1rem;
    }
    width: 24%;

    .ant-collapse {
      border-radius: 1rem;
      box-shadow: 0px 1px 5px rgb(0 0 0 / 20%);
      border: 1px solid #d9d9d9;

      .ant-collapse-header {
        font-weight: bold;
        font-size: 16px;
      }

      div[aria-expanded="true"] {
        color: #d6781e;
      }
    }
  }

  .col-right {
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
    width: 70%;
  }

  .input-containter {
    @media only screen and (max-width: 1200px) {
      display: flex;
    }
  }

  .ant-form-item {
    @media only screen and (max-width: 1200px) {
      padding: 10px;
    }
  }
`;

const reportPageHeaderStyles = css`
  display: flex;
  justify-content: space-between;
`;

const reportTitleAndContentsStyles = css`
  h1 {
    text-align: center;
  }

  .bold-text {
    font-weight: bold;
    text-align: left;
  }

  .notes {
    width: 50%;
  }
`;

const reportPropertiesStyles = css`
  list-style-type: none;
  padding: 0;
  height: 7rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;

  li {
    margin-bottom: 8px;

    .bold-text {
      font-weight: bold;
      display: inline;
      font-size: 14px;
    }
  }
`;

const mainContentStyles = css`
  // text-align: center;
  // margin: 5rem;
`;

const reportFooterPropertiesStyles = css`
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
    font-size: 14px;
    text-align: left;
  }

  .left-column {
    display: block;
  }

  .bold-text {
    font-weight: bold;
  }

  .notes {
    width: 20rem;
  }
`;

const bottomFooterTextStyles = css`
  display: flex;
  margin-top: 10px;
  text-align: left;
  .footer-text {
    margin-left: 20px;
    font-style: italic;
    flex: 1;
  }
  .footer-picture {
    width: 40px;
    height: 40px;
  }
`;
const loadingScreenStyles = (reportDownload) => css`
  position: absolute;
  top: 25%;
  right: ${reportDownload ? "40%" : "46%"};
  z-index: 1;

  svg {
    margin: 1rem auto;
  }

  p {
    font-size: 18px;
    font-weight: bold;
    color: #d6781e;
  }
`;

const noCalculationsStyles = (noCalculation, reportPage) => css`
  text-align: center;

  ${noCalculation
    ? css`
        opacity: 0.2;
      `
    : css``}
`;

const page3ContentStyles = css`
  p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
`;

const emptyValuesTextStyles = css`
  position: absolute;
  top: 24%;
  right: 18%;
  font-weight: bold;
  font-size: 22px;
  z-index: 1;
  color: #d6781e;
`;

const reportDownloadContainer = css`
  opacity: 0;
  position: absolute;
  bottom: 0;
`;
