//Data Reducer types
export const SET_FLUX_DATA = "SET_FLUX_DATA";
export const SET_FORMATTED_FLUX_DATA = "SET_FORMATTED_FLUX_DATA";
export const SET_DATA_INPUT_PARAMETERS = "SET_DATA_INPUT_PARAMETERS";
export const SET_REPORTED_VALUES = "SET_REPORTED_VALUES";
export const SET_REPORT_METADATA = "SET_REPORT_METADATA";
export const SET_INTERPOLATION_PARAMETERS = "SET_INTERPOLATION_PARAMETERS";
export const SET_INTERPOLATION_CHANGED = "SET_INTERPOLATION_CHANGED";
export const RESET_ALL = "RESET_ALL";
export const IMPORT_JSON_DATA = "IMPORT_JSON_DATA";
export const CLEAR_PAGES = "CLEAR_PAGES";
export const SET_PAGE_TYPE = "SET_PAGE_TYPE";
export const CALCULATE_INTERPOLATION = {
  REQUESTED: "CALCULATE_INTERPOLATION_REQUESTED",
  SUCCEEDED: "CALCULATE_INTERPOLATION_SUCCEEDED",
  FAILED: "CALCULATE_INTERPOLATION_FAILED",
};
