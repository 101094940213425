import {
  SET_DATA_INPUT_PARAMETERS,
  SET_FLUX_DATA,
  SET_FORMATTED_FLUX_DATA,
  SET_REPORTED_VALUES,
  SET_REPORT_METADATA,
  SET_INTERPOLATION_PARAMETERS,
  CALCULATE_INTERPOLATION,
  SET_INTERPOLATION_CHANGED,
  RESET_ALL,
  IMPORT_JSON_DATA,
  CLEAR_PAGES,
  SET_PAGE_TYPE,
} from "./types";

export const pageTypeEnum = Object.freeze({
  DataInput: "dataInput",
  WorkSpace: "workSpace",
  Report: "report",
});

const initialState = {
  dataInputParameters: {
    dataType: "L",
    numOfConditions: "16",
    measuredDataRows: "25",
  },
  fluxData: null,
  formattedFluxData: null,
  reportedValues: null,
  reportMetadata: null,
  interpolationParameters: null,
  interpolationTableData: null,
  interpolationPlotData: null,
  interpolationRawOutput: null,
  reportTable1Data: null,
  reportTable2Data: null,
  reportHeaderInfo: null,
  interpolationError: null,
  interpolationChanged: false,
  pageType: pageTypeEnum.DataInput,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DATA_INPUT_PARAMETERS:
      const { dataInputParameters } = action.payload;
      return {
        ...state,
        dataInputParameters,
      };
    case SET_FLUX_DATA:
      const { fluxData } = action.payload;
      return {
        ...state,
        fluxData,
      };
    case SET_FORMATTED_FLUX_DATA:
      const { formattedFluxData } = action.payload;
      return {
        ...state,
        formattedFluxData,
        interpolationError: null,
      };
    case SET_REPORTED_VALUES:
      const { reportedValues } = action.payload;
      return {
        ...state,
        reportedValues,
      };
    case SET_REPORT_METADATA:
      const { reportMetadata } = action.payload;
      return {
        ...state,
        reportMetadata,
      };
    case SET_INTERPOLATION_PARAMETERS:
      const { interpolationParameters } = action.payload;
      return {
        ...state,
        interpolationParameters,
      };
    case CALCULATE_INTERPOLATION.SUCCEEDED:
      const {
        interpolationTableOutput,
        interpolationPlotOutput,
        interpolationRawOutput,
        reportTable1Output,
        reportTable2Output,
        reportHeaderInfo,
      } = action.payload;
      return {
        ...state,
        interpolationTableData: interpolationTableOutput,
        interpolationPlotData: interpolationPlotOutput,
        interpolationRawOutput: interpolationRawOutput,
        reportTable1Data: reportTable1Output,
        reportTable2Data: reportTable2Output,
        reportHeaderInfo: reportHeaderInfo,
        interpolationError: null,
      };
    case CALCULATE_INTERPOLATION.FAILED:
      const { type } = action.payload;
      if (type === "network") {
        return {
          ...state,
          interpolationError: action.payload,
        };
      } else {
        return {
          ...state,
          interpolationError: action.payload,
          interpolationTableData: null,
          interpolationPlotData: null,
          interpolationRawOutput: null,
          reportTable1Data: null,
          reportTable2Data: null,
        };
      }
    case SET_INTERPOLATION_CHANGED:
      return {
        ...state,
        interpolationChanged: !state.interpolationChanged,
      };
    case RESET_ALL:
      return {
        ...state,
        fluxData: null,
        formattedFluxData: null,
        reportedValues: null,
        interpolationParameters: null,
        interpolationTableData: null,
        interpolationPlotData: null,
        interpolationRawOutput: null,
        reportTable1Data: null,
        reportTable2Data: null,
        reportHeaderInfo: null,
        interpolationError: null,
        interpolationChanged: false,
      };
    case IMPORT_JSON_DATA:
      const { input, output } = action.payload.jsonData;
      return {
        ...state,
        dataInputParameters: input.dataInputParameters,
        fluxData: input.fluxData,
        interpolationParameters: input.interpolationParameters,
        reportedValues: input.reportedValues,
        formattedFluxData: output.formattedFluxData,
        interpolationTableData: output.interpolationTableData,
        interpolationPlotData: output.interpolationPlotData,
        interpolationRawOutput: output.interpolationRawOutput,
        reportTable1Data: output.reportTable1Data,
        reportTable2Data: output.reportTable2Data,
        reportHeaderInfo: output.reportHeaderInfo,
        reportMetadata: output.reportMetadata,
      };
    case CLEAR_PAGES:
      if (action.payload.isInterpolation) {
        return {
          ...state,
          reportedValues: null,
          interpolationParameters: null,
          interpolationTableData: null,
          interpolationPlotData: null,
          interpolationRawOutput: null,
          reportTable1Data: null,
          reportTable2Data: null,
          reportHeaderInfo: null,
          interpolationError: null,
          interpolationChanged: false,
        };
      } else {
        return {
          ...state,
          reportedValues: null,
          reportTable1Data: null,
          reportTable2Data: null,
          reportHeaderInfo: null,
          interpolationError: null,
          interpolationChanged: false,
        };
      }
    case SET_PAGE_TYPE:
      return {
        ...state,
        pageType: action.payload.view,
      };
    default:
      return state;
  }
}
