/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setReportedData,
  setReportMetadata,
  calculateInterpolation,
  setInterpolationParameters,
  setInterpolationChanged,
} from "redux/actions";
import { pageTypeEnum } from "redux/reducers";

import { Form, Button, Input, Select, Collapse } from "antd";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const { Panel } = Collapse;
const { Option } = Select;

const ParametersForm = () => {
  const dispatch = useDispatch();

  let fluxMaintenanceLevelOptions = [];
  for (let i = 70; i <= 95; i++) {
    fluxMaintenanceLevelOptions.push(
      <Option key={i} value={i}>
        {i}%
      </Option>
    );
  }

  const formattedFluxData = useSelector(
    (state) => state.dataReducers.formattedFluxData
  );
  const interpolationParameters = useSelector(
    (state) => state.dataReducers.interpolationParameters
  );
  const reportedValues = useSelector(
    (state) => state.dataReducers.reportedValues
  );
  const reportMetadata = useSelector(
    (state) => state.dataReducers.reportMetadata
  );
  const interpolationError = useSelector(
    (state) => state.dataReducers.interpolationError
  );
  const pageType = useSelector((state) => state.dataReducers.pageType);

  const [collapseView, setCollapseView] = useState(["1"]);
  const [longestHour, setLongestHour] = useState(null);

  useEffect(() => {
    let hoursArray = [];
    Object.values(formattedFluxData).forEach((condition) => {
      hoursArray = [...hoursArray, ...condition.measuredData.hours];
    });
    setLongestHour(Math.max(...hoursArray));
  }, [formattedFluxData]);

  // useEffect(() => {
  //   setCollapseView(pageType === "workSpace" ? ["1"] : ["1", "2"]);
  // }, [pageType]);

  const { inSituTemperature, inSituCurrent } = interpolationParameters || {};
  const {
    fluxMaintenanceLevel,
    projectedFluxMaintenance,
    ratedMaxCurrent,
    nominalForwardVoltage,
  } = reportedValues || {};
  const { description, report, company, date, notes } = reportMetadata || {};

  const submitInterpolationParameters = (values) => {
    const { inSituTemperature, inSituCurrent } = values;
    dispatch(setInterpolationParameters(values));
    const interpolationData = {
      interpolationParameters: {
        inSituTemperature: parseFloat(inSituTemperature),
        inSituCurrent: parseFloat(inSituCurrent),
        fluxType: "Luminous",
      },
    };

    const { fluxMaintenanceLevel, projectedFluxMaintenance } =
      reportedValues || {};

    if (fluxMaintenanceLevel && projectedFluxMaintenance) {
      dispatch(setInterpolationChanged());
      dispatch(
        calculateInterpolation(
          formattedFluxData,
          interpolationData,
          {
            fluxMaintenanceLevel,
            projectedFluxMaintenanceHours: parseInt(projectedFluxMaintenance),
          },
          "report"
        )
      );
    } else {
      dispatch(
        calculateInterpolation(
          formattedFluxData,
          interpolationData,
          {},
          "interpolation"
        )
      );
    }
  };

  const submitReportedValues = (values) => {
    const {
      inSituTemperature,
      inSituCurrent,
      fluxMaintenanceLevel,
      projectedFluxMaintenance,
      ratedMaxCurrent,
      nominalForwardVoltage,
    } = values;

    const interpolationData = {
      interpolationParameters: {
        inSituTemperature: parseFloat(inSituTemperature),
        inSituCurrent: parseFloat(inSituCurrent),
        fluxType: "Luminous",
      },
    };
    const reportedValues = {
      fluxMaintenanceLevel,
      projectedFluxMaintenanceHours: parseInt(projectedFluxMaintenance),
    };

    dispatch(
      setInterpolationParameters({
        inSituTemperature,
        inSituCurrent,
      })
    );
    dispatch(
      setReportedData({
        fluxMaintenanceLevel,
        projectedFluxMaintenance,
        ratedMaxCurrent,
        nominalForwardVoltage,
      })
    );

    if (
      !projectedFluxMaintenance ||
      projectedFluxMaintenance <= 6 * longestHour
    ) {
      dispatch(
        calculateInterpolation(
          formattedFluxData,
          interpolationData,
          reportedValues,
          "report"
        )
      );
    }
  };

  const submitReportMetadataValues = (values) => {
    dispatch(setReportMetadata(values));
  };

  const handleChangeCollapse = (values) => {
    setCollapseView(values);
  };

  const InterpolationFormItems = () => {
    return (
      <div className="input-containter">
        {interpolationError &&
          interpolationError?.type !== "network" &&
          "error" && (
            <div style={{ marginBottom: "1rem" }}>
              <b
                style={{
                  color: "#d6781e",
                  display: "block",
                  marginBottom: "1rem",
                }}
              >
                <i>Error: {interpolationError?.message}</i>
              </b>
              {pageType === pageTypeEnum.Report && (
                <b>
                  <i>
                    Consider using the Work Space to find an appropriate
                    interpolation condition.
                  </i>
                </b>
              )}
            </div>
          )}
        <Form.Item
          // hasFeedback
          // tooltip="Data Type"
          label="In-situ temperature (°C):"
          name="inSituTemperature"
          rules={[
            {
              required: true,
              message: "Please input in-situ temperature",
            },
          ]}
          // validateStatus={
          //   interpolationError &&
          //   interpolationError?.type !== "network" &&
          //   "error"
          // }
          // help={
          //   interpolationError &&
          //   interpolationError?.type !== "network" &&
          //   interpolationError?.message
          // }
        >
          <Input />
        </Form.Item>
        <Form.Item
          // hasFeedback
          // tooltip="A test condition is a combination of case temperature and drive current. For example, 350 mA at 55 C is one condition."
          label="In-situ current (mA)::"
          name="inSituCurrent"
          rules={[
            {
              required: true,
              message: "Please input in-situ current",
            },
          ]}
          // validateStatus={numOfConditions.validationStatus}
          // help={numOfConditions.errorMsg}
        >
          <Input />
        </Form.Item>
      </div>
    );
  };

  const ReportedValuesFormItems = () => {
    return (
      <div className="input-containter">
        <Form.Item
          // hasFeedback
          // tooltip="Data Type"
          label="Flux maintenance level::"
          name="fluxMaintenanceLevel"
          rules={[
            {
              required: true,
              message: "Please input flux maintenance level",
            },
          ]}
          // validateStatus={dataType.validationStatus}
          // help={dataType.errorMsg}
        >
          <Select onChange={() => {}}>
            {fluxMaintenanceLevelOptions.map((option) => option)}
          </Select>
        </Form.Item>
        <Form.Item
          hasFeedback
          // tooltip="A test condition is a combination of case temperature and drive current. For example, 350 mA at 55 C is one condition."
          label="Projected flux maintenance (hours)::"
          name="projectedFluxMaintenance"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input projected flux maintenance",
          //   },
          // ]}
          validateStatus={projectedFluxMaintenance > 6 * longestHour && "error"}
          help={
            projectedFluxMaintenance > 6 * longestHour &&
            "Projected flux maintenance value must be less than or equal to 6X projection limit"
          }
        >
          <Input />
        </Form.Item>
        <div className="input-containter">
          <h3>LED Package Characteristics</h3>
          <Form.Item
            // hasFeedback
            tooltip="These values should match the LED package specification sheet."
            label="Rated max current (mA)::"
            name="ratedMaxCurrent"
            rules={[
              {
                required: true,
                message: "Please input rated max current",
              },
            ]}
            // validateStatus={dataType.validationStatus}
            // help={dataType.errorMsg}
          >
            <Input />
          </Form.Item>
          <Form.Item
            // hasFeedback
            // tooltip="A test condition is a combination of case temperature and drive current. For example, 350 mA at 55 C is one condition."
            label="Nominal forward voltage (V)::"
            name="nominalForwardVoltage"
            rules={[
              {
                required: true,
                message: "Please input nominal forward voltage",
              },
            ]}
            // validateStatus={numOfConditions.validationStatus}
            // help={numOfConditions.errorMsg}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
    );
  };

  return (
    <Collapse activeKey={collapseView} onChange={handleChangeCollapse}>
      {pageType === pageTypeEnum.WorkSpace ? (
        <Panel header="Interpolation Parameters" key="1">
          <Form
            {...layout}
            initialValues={{
              inSituTemperature,
              inSituCurrent,
            }}
            onFinish={submitInterpolationParameters}
          >
            <InterpolationFormItems />
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      ) : (
        <Panel header="Reported Values" key="1">
          <Form
            {...layout}
            initialValues={{
              inSituTemperature,
              inSituCurrent,
              fluxMaintenanceLevel,
              projectedFluxMaintenance,
              ratedMaxCurrent,
              nominalForwardVoltage,
            }}
            onFinish={submitReportedValues}
          >
            <InterpolationFormItems />
            <ReportedValuesFormItems />
            <div className="input-containter">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update Report
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Panel>
      )}
      <Panel
        header="Report Metadata"
        key="2"
        collapsible={pageType === pageTypeEnum.WorkSpace && "disabled"}
      >
        <Form
          {...layout}
          initialValues={{
            description,
            report,
            company,
            date,
            notes,
          }}
          onFinish={submitReportMetadataValues}
        >
          <div className="input-containter">
            <Form.Item
              // hasFeedback
              // tooltip="These values should match the LED package specification sheet."
              label="Description of tested light source::"
              name="description"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input description of tested light source",
              //   },
              // ]}
              // validateStatus={dataType.validationStatus}
              // help={dataType.errorMsg}
            >
              <Input.TextArea className="text-box" />
            </Form.Item>
            <Form.Item
              // hasFeedback
              // tooltip="A test condition is a combination of case temperature and drive current. For example, 350 mA at 55 C is one condition."
              label="Report generated by::"
              name="report"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input report generated by",
              //   },
              // ]}
              // validateStatus={numOfConditions.validationStatus}
              // help={numOfConditions.errorMsg}
            >
              <Input />
            </Form.Item>
            <Form.Item
              // hasFeedback
              // tooltip="A test condition is a combination of case temperature and drive current. For example, 350 mA at 55 C is one condition."
              label="Company::"
              name="company"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input company",
              //   },
              // ]}
              // validateStatus={numOfConditions.validationStatus}
              // help={numOfConditions.errorMsg}
            >
              <Input />
            </Form.Item>
            <Form.Item
              // hasFeedback
              // tooltip="A test condition is a combination of case temperature and drive current. For example, 350 mA at 55 C is one condition."
              label="Date::"
              name="date"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input data",
              //   },
              // ]}
              // validateStatus={numOfConditions.validationStatus}
              // help={numOfConditions.errorMsg}
            >
              <Input />
            </Form.Item>
            <Form.Item
              // hasFeedback
              // tooltip="A test condition is a combination of case temperature and drive current. For example, 350 mA at 55 C is one condition."
              label="Notes::"
              name="notes"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input notes",
              //   },
              // ]}
              // validateStatus={numOfConditions.validationStatus}
              // help={numOfConditions.errorMsg}
            >
              <Input.TextArea />
            </Form.Item>
            <div className="input-containter">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update Report Metadata
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Panel>
    </Collapse>
  );
};

export default ParametersForm;
