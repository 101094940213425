import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { tm21Reducer, tm21Saga } from "./redux";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  // Middleware for intercepting and dispatching navigation actions
  routerMiddleware(history),
  sagaMiddleware,
];

const store = createStore(tm21Reducer, applyMiddleware(...middlewares, logger));

sagaMiddleware.run(tm21Saga);

export default store;

if (module.hot) {
  module.hot.accept("./redux/reducers", () => {
    const nextRootReducer = require("./redux").default; // eslint-disable-line

    store.replaceReducer(nextRootReducer.default || nextRootReducer);
  });
}
