import { combineReducers } from "redux";

import dataReducers from "./reducers";
import tm21Saga from "./sagas";

const tm21Reducer = combineReducers({
  dataReducers,
});

export { tm21Saga, tm21Reducer };
