/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";

import {
  getFluxData,
  reactDataSheetStyles,
  conditionsTable,
  measurementTable,
  conditionsTableLabelsStyles,
} from "pages/calculator";

import ReactDataSheet from "react-datasheet";

export const getInterpolationPointsData = (
  interpolationRawOutput,
  formattedFluxData
) => {
  let interpolationReportTableData = [];
  const stringifiedCurrentInterpolation =
    interpolationRawOutput?.currentInterpolation.map((values) =>
      JSON.stringify(values)
    );
  Object.values(formattedFluxData).forEach((fluxData) => {
    const tempAndCurrent = [
      fluxData.testData.caseTemperature,
      fluxData.testData.driveCurrent,
    ];
    if (
      stringifiedCurrentInterpolation?.includes(JSON.stringify(tempAndCurrent))
    ) {
      interpolationReportTableData.push(fluxData);
    }
  });
  return interpolationReportTableData;
};

const ReportInputData = () => {
  const formattedFluxData = useSelector(
    (state) => state.dataReducers.formattedFluxData
  );
  // const interpolationPlotData = useSelector(
  //   (state) => state.dataReducers.interpolationPlotData
  // );
  const interpolationRawOutput = useSelector(
    (state) => state.dataReducers.interpolationRawOutput
  );

  const [tableFluxData, setTableFluxData] = useState(null);

  useEffect(() => {
    if (formattedFluxData && interpolationRawOutput) {
      const interpolationReportTableData = getInterpolationPointsData(
        interpolationRawOutput,
        formattedFluxData
      );
      const numberOfConditions = interpolationReportTableData.length;
      const measuredDataRows = interpolationReportTableData.map(
        (data) => data.measuredData.flux.length
      );
      const fluxData = getFluxData(
        numberOfConditions,
        Math.max(...measuredDataRows),
        interpolationReportTableData
      );
      setTableFluxData(fluxData);
    }
  }, [formattedFluxData, interpolationRawOutput]);

  return (
    <div>
      <h2 style={{ marginBottom: "2rem", textAlign: "center" }}>
        User Entered ANSI/IES LM-80 Data
      </h2>
      <Row>
        <Col span={4}>
          <ul
            style={{ marginTop: "1.3rem", marginLeft: "3rem" }}
            css={conditionsTableLabelsStyles}
          >
            <li></li>
            <li>Case temperature (°C):</li>
            <li>Drive Current (mA):</li>
            <li>Number of units tested:</li>
            <li>Number of failures:</li>
            <li style={{ marginTop: "2.5rem" }}></li>
            <li>Measured data:</li>
          </ul>
        </Col>
        <Col span={10} css={dataTableColumnStyles}>
          <div css={DataSheetsContainerStyles}>
            {tableFluxData !== null && (
              <div>
                <ReactDataSheet
                  key="conditions-table"
                  css={[reactDataSheetStyles, conditionsTable]}
                  data={tableFluxData.conditionTable}
                  valueRenderer={(cell) => cell.value}
                />
                <ReactDataSheet
                  key="measurements-table"
                  css={[reactDataSheetStyles, measurementTable]}
                  data={tableFluxData.measurementTable}
                  valueRenderer={(cell) => cell.value}
                />
              </div>
            )}
          </div>
          <br />
          <br />
        </Col>
      </Row>
    </div>
  );
};

export default ReportInputData;

const DataSheetsContainerStyles = css`
  .data-grid-container .data-grid {
    border: 2px solid #b2b2b2;
  }
`;

const dataTableColumnStyles = css`
  margin-left: 4rem;

  @media (min-width: 1200px) and (max-width: 1500px) {
    margin-left: 6rem;
  }

  @media (min-width: 1750px) {
    margin-left: 2rem;
  }
`;
