/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import Plot from "react-plotly.js";

import { LoadingScreen } from "components/loadingScreen";
import { getInterpolationPointsData } from "../reportInputData";

import { useSelector } from "react-redux";

const ReportPlot = () => {
  const formattedFluxData = useSelector(
    (state) => state.dataReducers.formattedFluxData
  );
  // const interpolationPlotData = useSelector(
  //   (state) => state.dataReducers.interpolationPlotData
  // );
  const reportTable1Data = useSelector(
    (state) => state.dataReducers.reportTable1Data
  );
  const interpolationRawOutput = useSelector(
    (state) => state.dataReducers.interpolationRawOutput
  );

  const [allInterpolationCoordinates, setAllInterpolationCoordinates] =
    useState([]);
  const [xAxisMax, setXAxisMax] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  useEffect(() => {
    if (reportTable1Data && interpolationRawOutput) {
      let targetInterpolatedFluxData = [];
      let interpolationPoints = [];
      const alpha = reportTable1Data.data.alpha;
      const beta = reportTable1Data.data.B;

      interpolationRawOutput.currentInterpolation.forEach((currentData) => {
        Object.values(formattedFluxData).forEach((fluxData) => {
          if (
            fluxData.testData.caseTemperature === currentData[0] &&
            fluxData.testData.driveCurrent === currentData[1]
          ) {
            targetInterpolatedFluxData.push(fluxData.measuredData);
            interpolationPoints.push([currentData[0], currentData[1]]);
          }
        });
      });

      let allInterpolationCoordinates = [];
      const colorsMapping = ["blue", "green", "orange", "red"]; //ll, lh, hl, hh
      const shapeMapping = ["circle", "square", "triangle-up", "diamond"]; //ll, lh, hl, hh
      targetInterpolatedFluxData.forEach((fluxData, index) => {
        const xCoordinates = fluxData.hours.map((hour) => hour);
        const yCoordinates = fluxData.flux.map((flux) => flux * 100);
        setXAxisMax(Math.max(...xCoordinates) * 6);

        let xProjectionCoordinates = [];
        let yProjectionCoordinates = [];
        for (
          let i = Math.max(...xCoordinates);
          i <= Math.max(...xCoordinates) * 6;
          i += 1000
        ) {
          const flux = (
            beta[index] *
            Math.pow(2.718282, -(alpha[index] * i)) *
            100
          ).toFixed(2);
          xProjectionCoordinates.push(i);
          yProjectionCoordinates.push(flux);
        }

        allInterpolationCoordinates.push({
          x: xCoordinates,
          y: yCoordinates,
          xProjectionCoordinates,
          yProjectionCoordinates,
          color: colorsMapping[index],
          shape: shapeMapping[index],
          interpolationPoints: interpolationPoints[index],
        });
      });

      let yInterpolationCoordinates = [];
      allInterpolationCoordinates[0]?.xProjectionCoordinates.forEach(
        (coord) => {
          const flux = (
            beta[4] *
            Math.pow(2.718282, -(alpha[4] * coord)) *
            100
          ).toFixed(2);
          yInterpolationCoordinates.push(flux);
        }
      );
      allInterpolationCoordinates.push({
        x: [],
        y: [],
        xProjectionCoordinates:
          allInterpolationCoordinates[0]?.xProjectionCoordinates,
        yProjectionCoordinates: yInterpolationCoordinates,
        color: "black",
      });
      setAllInterpolationCoordinates(allInterpolationCoordinates);
    }
  }, [formattedFluxData, reportTable1Data, interpolationRawOutput]);

  useEffect(() => {
    const interpolationReportTableData = getInterpolationPointsData(
      interpolationRawOutput,
      formattedFluxData
    );

    if (interpolationReportTableData.length < 4) {
      allInterpolationCoordinates.splice(
        1,
        4 - interpolationReportTableData.length
      );
    }
  }, [interpolationRawOutput, allInterpolationCoordinates, formattedFluxData]);

  useEffect(() => {
    let combinedYAxisArray = [];
    let yAxis = [];
    if (allInterpolationCoordinates.length !== 0) {
      allInterpolationCoordinates.forEach((data) => {
        const convertToFloat = data.yProjectionCoordinates.map((item) =>
          parseFloat(item)
        );
        combinedYAxisArray = [
          ...combinedYAxisArray,
          ...data.y,
          ...convertToFloat,
        ];
      });
      const minY = Math.round(Math.min(...combinedYAxisArray));
      const maxY = Math.round(Math.max(...combinedYAxisArray));

      // Setting axis dynamically
      if (minY >= 1000) {
        for (let i = minY - 1000; i <= maxY + 1000; i = i + 500) {
          yAxis.push(i);
        }
      } else if (minY >= 100) {
        for (let i = minY - 100; i <= maxY + 100; i = i + 100) {
          yAxis.push(i);
        }
      } else {
        for (let i = minY - 10; i <= maxY + 10; i = i + 10) {
          yAxis.push(i);
        }
      }

      setYAxis(yAxis);
    }
  }, [allInterpolationCoordinates]);

  const getWidth = () => {
    if (screenWidth > 1356) {
      return 800;
    } else if (screenWidth > 1290) {
      return 760;
    } else if (screenWidth < 1290 && screenWidth > 910) {
      return 700;
    } else if (screenWidth < 910 && screenWidth > 805) {
      return 600;
    } else if (screenWidth < 805 && screenWidth > 705) {
      return 500;
    } else if (screenWidth < 705 && screenWidth > 605) {
      return 400;
    } else if (screenWidth < 605) {
      return 300;
    }
  };

  const dots = allInterpolationCoordinates.map((data, index) => {
    const item = {
      x: data.x,
      y: data.y,
      type: "scatter",
      mode: "markers",
      marker: {
        color: data.color,
        symbol: data.shape,
        size: data.shape === "triangle-up" ? 10 : 7,
      },
      name: `${data.interpolationPoints && data.interpolationPoints[0]}°C, ${
        data.interpolationPoints && data.interpolationPoints[1]
      } mA`,
    };
    return item;
  });

  const projections = allInterpolationCoordinates.map((data, index) => {
    const item = {
      x: data.xProjectionCoordinates,
      y: data.yProjectionCoordinates,
      type: "scatter",
      mode: "lines",
      marker: { color: data.color },
      line:
        data.color === "black"
          ? {
              dash: "dash",
              width: 2,
            }
          : { width: 2 },
      showlegend: data.color === "black" ? true : false,
      name:
        data.color === "black"
          ? "Interpolation"
          : `${data.interpolationPoints && data.interpolationPoints[0]}°C, ${
              data.interpolationPoints && data.interpolationPoints[1]
            } mA Interpolation`,
    };
    return item;
  });

  if (reportTable1Data) {
    if (allInterpolationCoordinates.length !== 0 && yAxis.length !== 0) {
      return (
        <Plot
          data={[
            // Dots
            ...dots,
            // Projections
            ...projections,
          ]}
          layout={{
            width: getWidth(),
            height: 500,
            legend: { orientation: "h", y: 25 },
            margin: {
              //   l: 70,
              //   r: 10,
              //   b: 10,
              t: 10,
              // pad: 4,
            },
            xaxis: {
              title: {
                text: "<b>Time (hrs)</b>",
                font: {
                  size: 15,
                },
              },
              range: [0, xAxisMax + 10000],
              showline: true,
              tickmode: "array",
              ticktext: [
                "0",
                "10,000",
                "20,000",
                "30,000",
                "40,000",
                "50,000",
                "60,000",
              ],
              fixedrange: true,
              mirror: "ticks",
              linewidth: 2,
              linecolor: "black",
            },
            yaxis: {
              title: {
                text: "<b>Luminous Flux Maintenance<br /> </b>",
                font: {
                  size: 15,
                },
              },
              range: [Math.min(...yAxis), Math.max(...yAxis)],
              showline: true,
              tickmode: "array",
              tickvals: yAxis,
              // ticktext: [
              //   "70%",
              //   "75%",
              //   "80%",
              //   "85%",
              //   "90%",
              //   "95%",
              //   "100%",
              //   "105%",
              //   "110%",
              // ],
              fixedrange: true,
              mirror: "ticks",
              linewidth: 2,
              linecolor: "black",
            },
          }}
          config={{
            displaylogo: false,
            // modeBarButtons: ["toImage"],
            // responsive: true,
          }}
        />
      );
    } else {
      return <LoadingScreen show={true} />;
    }
  } else {
    return <div css={noPlotNoteStyles}>No data</div>;
  }
};

export default ReportPlot;

const noPlotNoteStyles = css`
  margin: 5rem 0;
`;
