import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { hot } from "react-hot-loader/root";

import { LoadingScreen } from "components/loadingScreen";
import Landing from "pages/landing";
// import Calculator from "./pages/calculator";
// import Interpolation from "./pages/interpolation";
import Report from "./pages/report";
import FourOhFour from "./pages/404";
import ErrorBoundary from "./ErrorBoundary";

const Router = () => {
  useEffect(() => {
    sessionStorage.setItem("session-progress", "true");
  }, []);

  // const Calculator = lazy(() => import("pages/calculator"));
  // const Interpolation = lazy(() => import("pages/interpolation"));
  // const Report = lazy(() => import("pages/report"));
  // const FourOhFour = lazy(() => import("pages/404"));

  return (
    <BrowserRouter>
      <Landing>
        <ErrorBoundary>
          <Suspense fallback={<LoadingScreen show={true} />}>
            <Switch>
              <Route exact path="/" component={Report}>
                <Redirect to="/calculator" />
              </Route>
              <Route exact path="/calculator" component={Report} />
              {/* <Route exact path="/interpolation" component={Interpolation} /> */}
              {/* <Route exact path="/report" component={Report} /> */}
              <Route component={FourOhFour} />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Landing>
    </BrowserRouter>
  );
};

export default hot(Router);
