import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";

// import { Grid } from "react-bootstrap";
// import { Card } from "pnnl-react-core";
import { Modal, Button, Tabs, Alert } from "antd";

// import { saveAs } from "file-saver";
// import SampleExcel from "data/TM-30-Web-Library-Excel-Input.xlsx";

import SubmitFeedbackModal from "../../components/submitFeedbackModal";

import "./index.scss";

const { TabPane } = Tabs;

const Home = () => {
  const [showBetaInfoModal, setShowBetaInfoModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  useEffect(() => {
    const inProgress = sessionStorage.getItem("session-progress");
    if (!inProgress) {
      setShowBetaInfoModal(true);
    }
  }, []);
  //betaInfoModal

  // const betaInfoModal = (
  //   <Modal
  //     title="Note"
  //     visible={showBetaInfoModal}
  //     onCancel={() => setShowBetaInfoModal(false)}
  //     footer={[
  //       <Button key="close" onClick={() => setShowBetaInfoModal(false)}>
  //         Close
  //       </Button>,
  //     ]}
  //   >
  //     This is a beta version of the ANSI/IES TM-21 Calculator. Please help us
  //     find and fix any bugs. The first official version is expected to launch soon.
  //   </Modal>
  // );

  return (
    <div className="overview-and-instructions">
      {/* {betaInfoModal} */}
      <SubmitFeedbackModal
        showFeedbackModal={showFeedbackModal}
        setShowFeedbackModal={setShowFeedbackModal}
      />
      <Alert
        message="This application works with updated versions of Chrome, Firefox, Edge and Safari. Older operating systems have not been tested."
        type="info"
        showIcon
        closable
      />
      {/* <Alert
        message="This is a beta version of TM-30-20 calculator, not a final version."
        type="info"
        showIcon
      /> */}
      <Tabs defaultActiveKey="1">
        <TabPane tab="Overview" key="1">
          <p>
            This application implements{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://store.ies.org/product/tm-21-21-projecting-long-term-luminous-photon-and-radiant-flux-maintenance-of-led-light-sources/"
            >
              ANSI/IES TM-21-21
            </a>
            ,{" "}
            <i>
              Projecting Long-Term Luminous Flux, Photon Flux, and Radiant Flux
              Maintenance of LED Light Sources
            </i>
            . It accepts ANSI/IES LM-80 data for luminous, photon, or radiant
            flux at up to 16 different combinations of case temperature and
            drive current. The user can specify a temperature and current, below
            the upper bounds of the input data, to calculate a projected flux
            maintenance value at a given time, and/or a projected time until a
            given flux maintenance threshold will be reached. The application
            facilitates the creation of a standardized report that summarizes
            the calculations and the input data.
          </p>
          <p>
            Questions and feedback about this tool should be directed to{" "}
            <a href={"mailto: tools@ies.org"}>tools@ies.org</a>. Suggestions for
            improvement are welcome.
          </p>
          <p>
            The development of this tool was supported by the U.S. Department of
            Energy’s Lighting R&D Program, part of the Building Technologies
            Office within the Office of Energy Efficiency and Renewable Energy
            (EERE).
          </p>
        </TabPane>
        <TabPane tab="Workflow" key="2">
          <p>There are three primary pages of this application:</p>
          <ul>
            <li>
              <strong>Data Input</strong>: Paste or upload LM-80 data to
              initiate calculations.
            </li>
            <li>
              <strong>Report</strong>: Input in-situ temperature and current,
              desired projection thresholds (e.g. L<sub>70</sub>, or Q
              <sub>80</sub>) and metadata to create a printable report.
            </li>
            <li>
              <strong>Work Space</strong>: Use to examine the extents of input
              data to determine appropriate interpolation conditions.
            </li>
          </ul>
          <p>
            Users can switch between pages by using the menu at the top right of
            the page or can progress from data input to report generation using
            the buttons within each page.{" "}
          </p>
        </TabPane>
        <TabPane tab="Data Input" key="3">
          <p>
            To begin entering data, specify the type of measurement (luminous
            flux, radiant flux, or photon flux) and use keyboard shortcuts to
            paste ANSI/IES LM-80 data from another application (or key in the
            data, if you wish). Note that data pasted from Excel will only
            include visible values, not the full precision available in the
            background of Excel. If that full precision is needed, change the
            visible precision in Excel prior to copying the data. In lieu of
            pasting data, you can upload a JSON file that was previously saved
            from the Results page of this application. Data should be entered in
            decimal format (i.e., if beginning with 0 hours, the initial
            measurement should be 1, not 100 or 100%).
          </p>
          <p>
            The input data may include up to 16 temperature and drive current
            conditions, with time and flux measured according to ANSI/IES LM-80.
            Empty columns are ignored. If the ANSI/IES LM-80 data set includes
            more conditions, then the data for the conditions that are furthest
            from the in-situ temperature/current condition should be omitted.
            Temperature, drive current, number of units tested, and number of
            failures are required for each condition. The number of measurements
            for each condition can vary.
          </p>
          <p>
            By default, the application can accept up to 25 measurement points
            for each condition. If you have more, you can generate a new table
            to your specifications. Note that existing data entered into the
            table will be lost.
          </p>
          <p>
            Omission of data that would fall in the calculation interval based
            on the total duration of the ANSI/IES LM-80 test may prevent
            calculations from being completed. Users are encouraged to carefully
            review data sets prior to entry, and to reference ANSI/IES LM-80-21
            reporting requirements for further information.
          </p>
          <p>
            Entry of data starting with hour 0 is encouraged, but not required.{" "}
          </p>
          <p>
            Once data input is complete, proceed to the Report page. It is
            possible to return to the Data Input page to edit the data.
          </p>
          <p>
            All data should be input in decimal percentage values in accordance
            with ANSI/IES LM-80. Data not in this format will be flagged for
            renormalization.
          </p>
        </TabPane>
        <TabPane tab="Report" key="4">
          <p>
            After entering measurement data, interpolation conditions, and LED
            package characteristics, the report page allows for specification of
            the projection targets and entry of product data. Once all data is
            entered, a report is generated.
          </p>
          <p>
            The user may select flux maintenance levels from 70% to 95% using
            the drop-down. Flux maintenance levels reached during ANSI/IES LM-80
            testing are interpolated per ANSI/IES TM-21-21 5.2.4.
          </p>
          <p>
            Flux maintenance projections are limited to a maximum of 6 times the
            total duration of the LM-80 testing conditions used for
            interpolation when ANSI/IES LM-80 report samples are 20 units or
            more (maximum of 5.5 times when less than 20 units). For example,
            when ANSI/IES TM-21 results are used correctly, projections using
            10,000 hours of ANSI/IES LM-80 data shall never exceed 60,000 hours.
            In this instance, a marketing claim of 100,000 hours to L70 would
            not be supported by IES standards.
          </p>
          <p>
            The generated report includes three pages. The first provides a
            tabular summary of the interpolation and the measured conditions
            used in the calculation. Temperatures and currents and the
            associated values of α and B are only displayed for the measured
            data used in the interpolation calculations; therefore, not all
            cells of the table will be populated. The α and B parameters for
            conditions which the minimum α rule applied are indicated by text
            that is red.
          </p>
          <p>
            The second page of the report provides a graph of the test and
            projection conditions. The third summarizes ANSI/IES TM-21
            projections for all measured conditions. All three pages are printed
            to one PDF using the button on the page. Bold italicized text
            denotes conditions for which the minimum α rule was applied.
          </p>
          <p>
            This application does not save any data beyond your current browser
            session. To retrieve these results later or share them with another
            user, you can click the Save as JSON button to download both the
            input and results to your computer. Saved files can be uploaded via
            the Data Input page.
          </p>
        </TabPane>
        <TabPane tab="Work Space" key="5">
          <p>
            The Work Space allows users to understand the limits of
            interpolation for projection of luminous flux at temperature and
            drive current conditions that weren’t measured. To interpolate,
            enter an in-situ (i.e., TMP) temperature and current, as well as the
            rated maximum current (DC) and nominal forward voltage of the LED
            package. The graph will display the data that will be used to create
            the projection.
          </p>
          <p>
            The in-situ conditions must be below the upper bounds of the tested
            temperature and drive current combinations. If the entered in-situ
            case temperature or drive current are below the minimum values in
            the inputted ANSI/IES LM-80 test conditions, then the closest
            (higher) values will be used for calculations.
          </p>
          <p>
            When the in-situ conditions entered are below the minimum drive
            current or temperature of the ANSI/IES LM-80 dataset, the
            interpolation lines in the graph may not display properly, although
            the final point(s) that would be used for projection will be
            correct.
          </p>
          <p>
            The graph only considers the in-situ temperature and drive current
            parameter space. It does not evaluate time intervals. It is possible
            that some interpolations may not be allowed due to missing data at
            one or more measurement intervals, or if the interval between
            measurements exceeds the maximum allowed.
          </p>
          <p>
            In the table, bold italicized text denotes conditions for which the
            minimum α rule was applied.
          </p>
        </TabPane>
        <TabPane tab="Version History" key="6">
          This tool was last updated on{" "}
          <span style={{ fontWeight: "bold" }}>
            {process.env.REACT_APP_BUILD_INFO}
          </span>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Home;
