import React, { useState } from "react";
import { Modal, Button } from "antd";

import Home from "../../pages/home";
import SubmitFeedbackModal from "../submitFeedbackModal";

const Footer = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showInitialInstructions, setShowInitialInstructions] = useState(true);

  const instructions = (
    <Modal
      width={1000}
      title="ANSI/IES TM-21 Calculator"
      visible={
        showInstructions ||
        (showInitialInstructions &&
          !sessionStorage.getItem("hide-instructions"))
      }
      closable={false}
      footer={
        <div>
          <strong>
            Note: If you want to access instructions again in future, please
            refer to the <b>User Manual</b> link in the footer.
          </strong>
          <br />
          <Button
            type="primary"
            key="close"
            onClick={() => {
              setShowInstructions(false);
              setShowInitialInstructions(false);
              sessionStorage.setItem("hide-instructions", true);
            }}
          >
            Close
          </Button>
        </div>
      }
    >
      <Home />
    </Modal>
  );

  //   const calculationDataFilesMenu = (
  //     <Menu className='calculation-data-files-dropdown-menu'>
  //       <Menu.Item>
  //         <a
  //           target='_blank'
  //           rel='noopener noreferrer'
  //           href={CESExcel}
  //           download='CES.xlsx'
  //         >
  //           The 99 CES (Excel)
  //         </a>
  //       </Menu.Item>
  //       <Menu.Item>
  //         <a
  //           target='_blank'
  //           rel='noopener noreferrer'
  //           href={CMFExcel}
  //           download='CMF.xlsx'
  //         >
  //           The 6 CMFs (Excel)
  //         </a>
  //       </Menu.Item>
  //       <Menu.Item>
  //         <a target='_blank' rel='noopener noreferrer' href=''>
  //           The C code
  //         </a>
  //       </Menu.Item>
  //     </Menu>
  //   );

  return (
    <div className="footer">
      {instructions}
      <div className="footer-left">
        <a target="_blank" rel="noopener noreferrer" href="https://www.ies.org">
          <img
            alt="app-logo"
            className="footer-picture"
            src={`${process.env.PUBLIC_URL}/images/IES-logo-trademark.png`}
          />
        </a>
        <div>
          {/* <div style={{ fontSize: '15px' }}>
            ©1906-{moment().year()} Illuminating Engineering Society
          </div> */}
          <div className="footer-links">
            {/* <a
              style={{ marginLeft: 0 }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ies.org/terms-of-use/"
            >
              Terms of Use
            </a>
            | */}
          </div>
        </div>
      </div>
      <div className="footer-links right-links">
        <div>
          <span onClick={() => setShowInstructions(true)}>
            TM-21 Calculator User Manual
          </span>
          |
          <span onClick={() => setShowFeedbackModal(true)}>
            Provide Feedback
          </span>
          {/* |
          <Dropdown overlay={calculationDataFilesMenu}>
            <a onClick={(e) => e.preventDefault()}>
              Calculation Data Files <DownOutlined className='dropdown-icon' />
            </a>
          </Dropdown> */}
          |
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.pnnl.gov/security-privacy"
          >
            Security & Privacy Policy
          </a>
          |
          <a
            target="_blank"
            rel="noopener noreferrer"
            href=" https://store.ies.org/product/tm-21-21-projecting-long-term-luminous-photon-and-radiant-flux-maintenance-of-led-light-sources/"
          >
            ANSI/IES TM-21-21
          </a>
        </div>
        <SubmitFeedbackModal
          showFeedbackModal={showFeedbackModal}
          setShowFeedbackModal={setShowFeedbackModal}
        />
      </div>
    </div>
  );
};

export default Footer;
