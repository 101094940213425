import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";

const SubmitFeedbackModal = ({ showFeedbackModal, setShowFeedbackModal }) => {
  // const [mailToIds, setMailToIds] = useState([
  //   "Michael.Royer@pnnl.gov",
  //   "amruthteja.gorantla@pnnl.gov"
  // ]);
  const [mailBody, setMailBody] = useState("");
  const [mailSubject, setMailSubject] = useState(
    "ANSI/IES TM-21 Calculator Feedback"
  );

  // const emailToOptions = [
  //   { label: "Royer, Michael (Owner)", value: "Michael.Royer@pnnl.gov" },
  //   {
  //     label: "Gorantla, Amruth (Maintainer/ Developer)",
  //     value: "amruthteja.gorantla@pnnl.gov"
  //   }
  // ];

  // const handleMailTo = checkedValues => {
  //   setMailToIds(checkedValues);
  // };
  const handleMailBody = (e) => {
    setMailBody(e.target.value);
  };
  const handleMailSubject = (e) => {
    setMailSubject(e.target.value);
  };

  return (
    <Modal
      title="Submit feedback"
      visible={showFeedbackModal}
      onCancel={() => setShowFeedbackModal(false)}
      footer={[
        <Button key="close" onClick={() => setShowFeedbackModal(false)}>
          Close
        </Button>,
      ]}
    >
      <Form>
        {/* <Form.Item>
          <Checkbox.Group
            options={emailToOptions}
            value={mailToIds}
            onChange={handleMailTo}
          />
        </Form.Item> */}
        <Form.Item
          initialValue="ANSI/IES TM-21 Calculator Feedback"
          label="Subject"
          name="subject"
          rules={[{ required: true }]}
        >
          <Input onChange={handleMailSubject} />
        </Form.Item>
        <Form.Item
          name="body"
          label="Body"
          rules={[{ required: true, message: "Email body is required" }]}
        >
          <Input.TextArea onChange={handleMailBody} />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ color: "white" }}
            href={`mailto: tools@ies.org?subject=${mailSubject}&body=${mailBody}`}
            type="primary"
            htmlType="submit"
          >
            Send
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubmitFeedbackModal;
