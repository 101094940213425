/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useSelector } from "react-redux";

import { Table } from "antd";
import { useMemo } from "react";

import { checkBoldRed, checkEmpty } from "../reportTable1";

const InterpolationTable = () => {
  const interpolationTableData = useSelector(
    (state) => state.dataReducers.interpolationTableData
  );

  const columns = [
    {
      title: "header-1",
      dataIndex: "header1",
      key: "header1",
      className: "header-color",
      width: 10,
      render: (text, row, index) => {
        let newValue = {
          children: <span className="bold-header">{text}</span>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };

        if (text === "Interpolation") {
          newValue = {
            children: <div className="bold-header vertical-header">{text}</div>,
            props: {
              colSpan: 1,
              rowSpan: 12,
            },
          };
        }
        return newValue;
      },
    },
    {
      title: "header-2",
      dataIndex: "header2",
      key: "header2",
      className: "header-color",
      width: 10,
      render: (text, row, index) => {
        let newValue = {
          children: <span className="bold-header">{text}</span>,
          props: {
            colSpan: 0,
            rowSpan: 0,
          },
        };
        if (text === "Data") {
          newValue = {
            children: <span className="bold-header">{text}</span>,
            props: {
              colSpan: 2,
              rowSpan: 2,
            },
          };
        }

        if (text === "Current" || text === "Temperature") {
          newValue = {
            children: <div className="bold-header vertical-header">{text}</div>,
            props: {
              colSpan: 1,
              rowSpan: 6,
            },
          };
        }

        if (["currentEmpty", "temperatureEmpty", "dataEmpty"].includes(text)) {
          newValue = {
            children: <span>{text}</span>,
            props: {
              colSpan: 0,
              rowSpan: 0,
            },
          };
        }
        return newValue;
      },
    },
    {
      title: "header-3",
      dataIndex: "header3",
      key: "header3",
      className: "header-color",
      width: 100,
      render: (text, row, index) => {
        let newValue = {
          children: <span className="bold-header">{text}</span>,
          props: {
            colSpan: 1,
            rowSpan: 1,
          },
        };

        if (text === "Temperature (°C)" || text === "Current (mA)") {
          newValue = {
            children: <span className="bold-header">{text}</span>,
            props: {
              colSpan: 3,
            },
          };
        }

        return newValue;
      },
      // render: (text, row, index) => {
      //   if (text) {
      //     return {
      //       children: <span>{text}</span>,
      //     };
      //   } else {
      //     return {
      //       children: <span>{text}</span>,
      //       props: {
      //         colSpan: 0,
      //         rowSpan: 0,
      //       },
      //     };
      //   }
      // },
    },
    {
      title: "Column-1",
      dataIndex: "column1",
      key: "column1",
      width: 150,
    },
    {
      title: "Column-2",
      dataIndex: "column2",
      key: "column2",
      width: 150,
    },
    {
      title: "Column-3",
      dataIndex: "column3",
      key: "column3",
      width: 150,
    },
    {
      title: "Column-4",
      dataIndex: "column4",
      key: "column4",
      width: 150,
    },
  ];

  const memoisedDataSource = useMemo(() => {
    let dataSource = [];
    let formatInterpolationTableData = {};
    const {
      current,
      data,
      interpolation,
      temperature,
      emptyCellsCondition1,
      emptyCellsCondition2,
    } = interpolationTableData || {};

    if (interpolationTableData) {
      temperature.forEach((temp, i) => {
        // Condition for columns 1 & 3
        const isEmpty1 =
          (i === 1 && emptyCellsCondition1) ||
          (i === 3 && emptyCellsCondition2);

        // Condition for columns 0 & 2
        const isEmpty2 =
          (i === 0 && emptyCellsCondition1) ||
          (i === 2 && emptyCellsCondition2);

        let newColumnEntry = [
          checkEmpty(temp, isEmpty1),
          checkEmpty(current[i], isEmpty1),
          data.alpha[i] && data.alpha[i] !== ""
            ? checkBoldRed(
                data.alpha[i],
                checkEmpty(data.alpha[i], isEmpty1),
                true
              )
            : "-",
          data.alpha[i] && data.alpha[i] !== ""
            ? checkBoldRed(
                data.alpha[i],
                checkEmpty(data.B[i], isEmpty1),
                false
              )
            : "-",
          interpolation.current.mAlpha[i] !== ""
            ? interpolation.current.mAlpha[i].toExponential(3)
            : "-",
          interpolation.current.bAlpha[i] !== ""
            ? interpolation.current.bAlpha[i].toExponential(3)
            : "-",
          interpolation.current.mB[i] !== ""
            ? interpolation.current.mB[i].toExponential(3)
            : "-",
          interpolation.current.bB[i] !== ""
            ? interpolation.current.bB[i].toFixed(4)
            : "-",

          interpolation.current.alpha[i] &&
          interpolation.current.alpha[i] !== ""
            ? checkBoldRed(
                interpolation.current.alpha[i],
                checkEmpty(interpolation.current.alpha[i], isEmpty2),
                true
              )
            : "-",

          interpolation.current.B[i][i] && interpolation.current.B[i][i] !== ""
            ? checkBoldRed(
                interpolation.current.alpha[i],
                checkEmpty(interpolation.current.B[i], isEmpty2),
                false
              )
            : "-",
          interpolation.temperature.eAlphaKB[i] !== ""
            ? interpolation.temperature.eAlphaKB[i].toExponential(3)
            : "-",
          interpolation.temperature.A[i] !== ""
            ? interpolation.temperature.A[i].toExponential(3)
            : "-",
          interpolation.temperature.mB[i] !== ""
            ? interpolation.temperature.mB[i].toExponential(3)
            : "-",
          interpolation.temperature.bB[i] !== ""
            ? interpolation.temperature.bB[i].toFixed(3)
            : "-",
          interpolation.temperature.alpha[i] &&
          interpolation.temperature.alpha[i] !== ""
            ? checkBoldRed(
                interpolation.temperature.alpha[i],
                interpolation.temperature.alpha[i],
                true
              )
            : "-",
          interpolation.temperature.B[i] &&
          interpolation.temperature.B[i] !== ""
            ? checkBoldRed(
                interpolation.temperature.alpha[i],
                interpolation.temperature.B[i],
                false
              )
            : "-",
        ];
        Object.assign(formatInterpolationTableData, {
          [`column${i + 1}`]: newColumnEntry,
        });
      });
    }

    const header1 = ["empty", "empty", "empty", "empty", "Interpolation"];
    const header2 = [
      "empty",
      "empty",
      "Data",
      "DataEmpty",
      "Current",
      "currentEmpty",
      "currentEmpty",
      "currentEmpty",
      "currentEmpty",
      "currentEmpty",
      "Temperature",
      "temperatureEmpty",
      "temperatureEmpty",
      "temperatureEmpty",
      "temperatureEmpty",
      "temperatureEmpty",
    ];
    const header3 = [
      "Temperature (°C)",
      "Current (mA)",
      <span>&alpha;</span>,
      "B",
      <span>
        m<sub>&alpha;</sub>
      </span>,
      <span>
        b<sub>&alpha;</sub>
      </span>,
      <span>
        m<sub>B</sub>
      </span>,
      <span>
        b<sub>B</sub>
      </span>,
      <span>&alpha;</span>,
      <span>B</span>,
      <span>
        E<sub>&alpha;</sub>/k<sub>B</sub>
      </span>,
      "A",
      <span>
        m<sub>B</sub>
      </span>,
      <span>
        b<sub>B</sub>
      </span>,
      <span>&alpha;</span>,
      "B",
    ];

    if (Object.keys(formatInterpolationTableData).length !== 0) {
      formatInterpolationTableData.column1.forEach((column1, i) => {
        dataSource.push({
          key: i,
          header1: header1[i],
          header2: header2[i],
          header3: header3[i],
          column1: column1,
          column2: formatInterpolationTableData.column2[i],
          column3: formatInterpolationTableData.column3[i],
          column4: formatInterpolationTableData.column4[i],
        });
      });
    }
    return dataSource;
  }, [interpolationTableData]);

  return (
    <Table
      css={interpolationTableStyles}
      // scroll={{ x: 200, y: 600 }}
      columns={columns}
      dataSource={memoisedDataSource}
      bordered
      pagination={false}
    />
  );
};

export default InterpolationTable;

const interpolationTableStyles = css`
  .ant-table-thead {
    display: none;
  }

  .ant-table-content {
    // border: 2px solid black;
  }

  .ant-table-header > table > thead > tr > th {
    font-weight: bold;
  }

  .ant-table-tbody > tr > td {
    padding: 5px 5px;
    text-align: center;
  }

  .bold-header {
    font-weight: bold;
  }

  .vertical-header {
    transform: rotate(270deg);
    font-size: 15px;
    margin: 0;
  }

  .header-color {
    background-color: #fafafa;
  }
`;

/////////////////////  Old Table Code  /////////////////////

// const columns = [
//   {
//     title: "T (°C)",
//     dataIndex: "temperature",
//     key: "temperature",
//     width: 60,
//   },
//   {
//     title: "Current (mA)",
//     dataIndex: "current",
//     key: "current",
//     width: 80,
//   },
//   {
//     title: "Data",
//     dataIndex: "data",
//     key: "data",
//     children: [
//       {
//         title: <span>&alpha;</span>,
//         dataIndex: "alpha",
//         key: "alpha",
//         width: 80,
//       },
//       {
//         title: "B",
//         dataIndex: "b",
//         key: "b",
//         width: 70,
//       },
//     ],
//   },
//   {
//     title: "Interpolation",
//     dataIndex: "interpolation",
//     key: "interpolation",
//     children: [
//       {
//         title: "Current",
//         dataIndex: "current",
//         key: "current",
//         children: [
//           {
//             title: (
//               <span>
//                 m<sub>&alpha;</sub>
//               </span>
//             ),
//             dataIndex: "mAlphaCurrent",
//             key: "mAlphaCurrent",
//             width: 70,
//             // render: (record) => {
//             //   return (
//             //     <span
//             //       className={`table-cell-span ${record ? "" : "disable"}`}
//             //     >
//             //       {record}
//             //     </span>
//             //   );
//             // },
//           },
//           {
//             title: (
//               <span>
//                 b<sub>&alpha;</sub>
//               </span>
//             ),
//             dataIndex: "bAlphaCurrent",
//             key: "bAlphaCurrent",
//             width: 80,
//           },
//           {
//             title: (
//               <span>
//                 m<sub>B</sub>
//               </span>
//             ),
//             dataIndex: "mBCurrent",
//             key: "mBCurrent",
//             width: 80,
//           },
//           {
//             title: (
//               <span>
//                 b<sub>B</sub>
//               </span>
//             ),
//             dataIndex: "bBCurrent",
//             key: "bBCurrent",
//             width: 80,
//           },
//           {
//             title: <span>&alpha;</span>,
//             dataIndex: "alphaCurrent",
//             key: "alphaCurrent",
//             width: 80,
//           },
//           {
//             title: <span>B</span>,
//             dataIndex: "BCurrent",
//             key: "BCurrent",
//             width: 80,
//           },
//         ],
//       },
//       {
//         title: "Temperature",
//         dataIndex: "temperature",
//         key: "temperature",
//         children: [
//           {
//             title: (
//               <span>
//                 E<sub>a</sub>/k<sub>B</sub>
//               </span>
//             ),
//             dataIndex: "eAlphaKB",
//             key: "eAlphaKB",
//             width: 80,
//           },
//           {
//             title: <span>A</span>,
//             dataIndex: "ATemperature",
//             key: "ATemperature",
//             width: 80,
//           },
//           {
//             title: (
//               <span>
//                 m<sub>B</sub>
//               </span>
//             ),
//             dataIndex: "mBTemperature",
//             key: "mBTemperature",
//             width: 80,
//           },
//           {
//             title: (
//               <span>
//                 b<sub>B</sub>
//               </span>
//             ),
//             dataIndex: "bBTemperature",
//             key: "bBTemperature",
//             width: 80,
//           },
//           {
//             title: <span>&alpha;</span>,
//             dataIndex: "alphaTemperature",
//             key: "alphaTemperature",
//             width: 80,
//           },
//           {
//             title: <span>B</span>,
//             dataIndex: "BTemperature",
//             key: "BTemperature",
//             width: 80,
//           },
//         ],
//       },
//     ],
//   },
// ];

// const memoisedDataSource = useMemo(() => {
//   let dataSource = [];
//   const { current, data, interpolation, temperature } =
//     interpolationTableData || {};
//   if (interpolationTableData) {
//     for (let i = 0; i < 4; i++) {
//       dataSource.push({
//         key: i,
//         temperature: temperature[i],
//         current: current[i],
//         alpha: data.alpha[i].toExponential(3),
//         b: data.B[i].toFixed(3),
//         mAlphaCurrent:
//           interpolation["current"]["mAlpha"][i] !== ""
//             ? interpolation["current"]["mAlpha"][i].toExponential(3)
//             : "-",
//         bAlphaCurrent:
//           interpolation["current"]["bAlpha"][i] !== ""
//             ? interpolation["current"]["bAlpha"][i].toExponential(3)
//             : "-",
//         mBCurrent:
//           interpolation["current"]["mB"][i] !== ""
//             ? interpolation["current"]["mB"][i].toExponential(3)
//             : "-",
//         bBCurrent:
//           interpolation["current"]["bB"][i] !== ""
//             ? interpolation["current"]["bB"][i].toFixed(4)
//             : "-",
//         BCurrent:
//           interpolation["current"]["B"][i] !== ""
//             ? interpolation["current"]["B"][i].toFixed(4)
//             : "-",
//         alphaCurrent:
//           interpolation["current"]["alpha"][i] !== ""
//             ? interpolation["current"]["alpha"][i].toExponential(3)
//             : "-",
//         eAlphaKB:
//           interpolation["temperature"]["eAlphaKB"][i] !== ""
//             ? interpolation["temperature"]["eAlphaKB"][i].toExponential(3)
//             : "-",
//         ATemperature:
//           interpolation["temperature"]["A"][i] !== ""
//             ? interpolation["temperature"]["A"][i].toExponential(3)
//             : "-",
//         mBTemperature:
//           interpolation["temperature"]["mB"][i] !== ""
//             ? parseFloat(interpolation["temperature"]["mB"][i]).toExponential(
//                 3
//               )
//             : "-",
//         bBTemperature:
//           interpolation["temperature"]["bB"][i] !== ""
//             ? parseFloat(interpolation["temperature"]["bB"][i]).toFixed(3)
//             : "-",
//         alphaTemperature:
//           interpolation["temperature"]["alpha"][i] !== ""
//             ? interpolation["temperature"]["alpha"][i].toExponential(3)
//             : "-",
//         BTemperature:
//           interpolation["temperature"]["B"][i] !== ""
//             ? interpolation["temperature"]["B"][i].toFixed(3)
//             : "-",
//       });
//     }
//   }
//   return dataSource;
// }, [interpolationTableData]);
