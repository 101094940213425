/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getInterpolationPointsData } from "../reportInputData";

import { Table, Tooltip } from "antd";

export const checkEmpty = (value, isEmpty) => {
  if (isEmpty) {
    return "-";
  } else {
    return value;
  }
};

export const checkBoldRed = (alpha, value, isExp) => {
  // const convertedValue = isExp ? value?.toExponential(3) : value?.toFixed(3);
  if (value === "-") {
    return "-";
  } else if (alpha === 0.000002) {
    return (
      <Tooltip title="Calculated alpha is below 2e-6, so alpha is reported at 2e-6, with alpha and B values displayed in bold red font, per the requirement of ANSI/IES TM-21-21.">
        <span css={boldRedCellStyles}>
          {isExp ? value?.toExponential(3) : value?.toFixed(4)}
        </span>
      </Tooltip>
    );
  } else {
    return isExp ? value?.toExponential(3) : value?.toFixed(4);
  }
};

const ReportTable1 = () => {
  const reportTable1Data = useSelector(
    (state) => state.dataReducers.reportTable1Data
  );
  const interpolationParameters = useSelector(
    (state) => state.dataReducers.interpolationParameters
  );
  const dataInputParameters = useSelector(
    (state) => state.dataReducers.dataInputParameters
  );
  const reportedValues = useSelector(
    (state) => state.dataReducers.reportedValues
  );
  const interpolationRawOutput = useSelector(
    (state) => state.dataReducers.interpolationRawOutput
  );
  const formattedFluxData = useSelector(
    (state) => state.dataReducers.formattedFluxData
  );

  const [numberOfAdditionalColumns, setNumberOfAdditionalColumns] = useState(
    []
  );

  useEffect(() => {
    let numberOfAdditionalColumns = [
      {
        title: "Column-1",
        dataIndex: "column1",
        key: "column1",
        width: 150,
      },
      {
        title: "Column-2",
        dataIndex: "column2",
        key: "column2",
        width: 150,
      },
      {
        title: "Column-3",
        dataIndex: "column3",
        key: "column3",
        width: 150,
      },
      {
        title: "Column-4",
        dataIndex: "column4",
        key: "column4",
        width: 150,
      },
      {
        title: "Column-5",
        dataIndex: "column5",
        key: "column5",
        width: 150,
      },
    ];
    const interpolationReportTableData = getInterpolationPointsData(
      interpolationRawOutput,
      formattedFluxData
    );

    // Limiting number of columns depending upon number of interpolation data points
    if (interpolationReportTableData.length < 4) {
      if (interpolationReportTableData.length === 2) {
        numberOfAdditionalColumns = [
          numberOfAdditionalColumns[0],
          numberOfAdditionalColumns[2],
        ];
      }

      if (interpolationReportTableData.length === 1) {
        numberOfAdditionalColumns = [numberOfAdditionalColumns[0]];
      }
    }
    setNumberOfAdditionalColumns(numberOfAdditionalColumns);
  }, [formattedFluxData, interpolationRawOutput]);

  const columns = [
    {
      title: "header-1",
      dataIndex: "header1",
      key: "header1",
      className: "header-color",
      width: 10,
      render: (text, row, index) => {
        let newValue = {
          children: <span className="bold-header">{text}</span>,
          props: {
            colSpan: 1,
            rowSpan: 1,
          },
        };

        if (text === "emptyBundle") {
          newValue = {
            children: <span></span>,
            props: {
              colSpan: 1,
              rowSpan: 9,
            },
          };
        }

        if (text === "Interpolation") {
          newValue = {
            children: <div className="bold-header vertical-header">{text}</div>,
            props: {
              colSpan: 1,
              rowSpan: 6,
            },
          };
        }

        if (text === "interpolationEmpty" || text === "emptyEmpty") {
          newValue = {
            children: <span>{text}</span>,
            props: {
              colSpan: 0,
              rowSpan: 0,
            },
          };
        }
        return newValue;
      },
    },
    {
      title: "header-2",
      dataIndex: "header2",
      key: "header2",
      className: "header-color",
      width: 10,
      render: (text, row, index) => {
        let newValue = {
          children: <span className="bold-header">{text}</span>,
          props: {
            colSpan: 1,
            rowSpan: 1,
          },
        };

        if (text === "empty") {
          newValue = {
            children: <span></span>,
            props: {
              colSpan: 1,
              rowSpan: 1,
            },
          };
        }

        if (text === "Data") {
          newValue = {
            children: <span className="bold-header">{text}</span>,
            props: {
              colSpan: 1,
              rowSpan: 8,
            },
          };
        }

        if (text === "Current" || text === "Temperature") {
          newValue = {
            children: <div className="bold-header vertical-header">{text}</div>,
            props: {
              colSpan: 1,
              rowSpan: 3,
            },
          };
        }

        if (["DataEmpty", "currentEmpty", "temperatureEmpty"].includes(text)) {
          newValue = {
            children: <span className="bold-header">{text}</span>,
            props: {
              colSpan: 0,
              rowSpan: 0,
            },
          };
        }
        return newValue;
      },
    },
    {
      title: "header-3",
      dataIndex: "header3",
      key: "header3",
      className: "header-color",
      width: 150,
      render: (text, row, index) => {
        let newValue = {
          children: <span className="bold-header">{text}</span>,
          props: {
            colSpan: 1,
            rowSpan: 1,
          },
        };

        return newValue;
      },
    },
    ...numberOfAdditionalColumns,
  ];

  const memoisedDataSource = useMemo(() => {
    let dataSource = [];
    let formatReportTable1Data = {};
    const {
      current,
      data,
      temperature,
      emptyCellsCondition1,
      emptyCellsCondition2,
    } = reportTable1Data || {};

    if (
      reportTable1Data &&
      interpolationParameters &&
      dataInputParameters &&
      reportedValues
    ) {
      data.parameter.forEach((parameter, i) => {
        // Condition for columns 1 & 3
        const isEmpty1 =
          (i === 1 && emptyCellsCondition1) ||
          (i === 3 && emptyCellsCondition2);

        // Condition for columns 0 & 2
        const isEmpty2 =
          (i === 0 && emptyCellsCondition1) ||
          (i === 2 && emptyCellsCondition2);

        let newColumnEntry = [
          typeof parameter === "string"
            ? parameter
            : isEmpty1
            ? "-"
            : `${parameter[0]}°C, ${parameter[1]} mA`,
          i === 4
            ? `${interpolationParameters.inSituTemperature}°C`
            : checkEmpty(data.temperatureC[i], isEmpty1),
          i === 4
            ? `${parseInt(interpolationParameters.inSituTemperature) + 273}K`
            : checkEmpty(Math.round(data.temperatureK[i]), isEmpty1),
          i === 4
            ? `${interpolationParameters.inSituCurrent} mA`
            : checkEmpty(data.lm80TestCurrent[i], isEmpty1),
          data.alpha[i] && data.alpha[i] !== ""
            ? checkBoldRed(
                data.alpha[i],
                checkEmpty(data.alpha[i], isEmpty1),
                true
              )
            : "-",
          data.B[i] && data.B[i] !== ""
            ? checkBoldRed(
                data.alpha[i],
                checkEmpty(data.B[i], isEmpty1),
                false
              )
            : "-",
          checkEmpty(data.hours[i], isEmpty1),
          checkEmpty(data.dut[i], isEmpty1),
          checkEmpty(data.failures[i], isEmpty1),
          current.alpha[i] && current.alpha[i] !== ""
            ? checkBoldRed(
                current.alpha[i],
                checkEmpty(current.alpha[i], isEmpty2),
                true
              )
            : "-",
          current.B[i] && current.B[i] !== ""
            ? checkBoldRed(
                current.alpha[i],
                checkEmpty(current.B[i], isEmpty2),
                false
              )
            : "-",
          checkEmpty(current.hours[i], isEmpty2),
          temperature.alpha[i] && temperature.alpha[i] !== ""
            ? checkBoldRed(temperature.alpha[i], temperature.alpha[i], true)
            : "-",
          temperature.B[i] && temperature.B[i] !== ""
            ? checkBoldRed(temperature.alpha[i], temperature.B[i], false)
            : "-",
          temperature.hours[i],
        ];
        Object.assign(formatReportTable1Data, {
          [`column${i + 1}`]: newColumnEntry,
        });
      });
    }

    const header1 = [
      "emptyBundle",
      "emptyEmpty",
      "emptyEmpty",
      "emptyEmpty",
      "emptyEmpty",
      "emptyEmpty",
      "emptyEmpty",
      "emptyEmpty",
      "emptyEmpty",
      "Interpolation",
      "interpolationEmpty",
      "interpolationEmpty",
      "interpolationEmpty",
      "interpolationEmpty",
      "interpolationEmpty",
    ];
    const header2 = [
      "empty",
      "Data",
      "DataEmpty",
      "DataEmpty",
      "DataEmpty",
      "DataEmpty",
      "DataEmpty",
      "DataEmpty",
      "DataEmpty",
      "Current",
      "currentEmpty",
      "currentEmpty",
      "Temperature",
      "temperatureEmpty",
      "temperatureEmpty",
    ];
    const header3 = [
      "Parameter",
      "Temperature (°C)",
      "Temperature (K)",
      "LM-80 test current (mA)",
      <span>&alpha;</span>,
      "B",
      `${dataInputParameters.dataType}${reportedValues?.fluxMaintenanceLevel} (hours)`,
      "#DUT",
      "#Failures",
      <span>&alpha;</span>,
      "B",
      `${dataInputParameters.dataType}${reportedValues?.fluxMaintenanceLevel} (hours)`,
      <span>&alpha;</span>,
      "B",
      `${dataInputParameters.dataType}${reportedValues?.fluxMaintenanceLevel} (hours)`,
    ];

    if (Object.keys(formatReportTable1Data).length !== 0) {
      formatReportTable1Data.column1.forEach((column1, i) => {
        dataSource.push({
          key: i,
          header1: header1[i],
          header2: header2[i],
          header3: header3[i],
          column1: column1,
          column2: formatReportTable1Data.column2[i],
          column3: formatReportTable1Data.column3[i],
          column4: formatReportTable1Data.column4[i],
          column5: formatReportTable1Data.column5[i],
        });
      });
    }
    return dataSource;
  }, [
    reportTable1Data,
    interpolationParameters,
    dataInputParameters,
    reportedValues,
  ]);

  return (
    <Table
      css={reportTable1Styles}
      columns={columns}
      dataSource={memoisedDataSource}
      bordered
      pagination={false}
    />
  );
};

export default ReportTable1;

const reportTable1Styles = css`
  margin-bottom: 2rem;

  .ant-table-thead {
    display: none;
  }

  .bold-header {
    font-weight: bold;
  }

  .vertical-header {
    transform: rotate(270deg);
    font-size: 15px;
    width: 10 rem;
    margin: 0;
  }

  .header-color {
    background-color: #fafafa;
  }

  .ant-table-tbody > tr > td {
    padding: 5px 5px;
    text-align: center;
  }
`;

const boldRedCellStyles = css`
  color: red;
  font-weight: bold;
`;

/////////////////////  Old Table Code  /////////////////////

// const columns = [
//   {
//     title: "Parameter",
//     dataIndex: "parameter",
//     key: "parameter",
//     width: 110,
//   },
//   {
//     title: "Data",
//     dataIndex: "data",
//     key: "data",
//     children: [
//       {
//         title: "T (°C)",
//         dataIndex: "temperatureC",
//         key: "temperatureC",
//         width: 80,
//       },
//       {
//         title: "T (K)",
//         dataIndex: "temperatureK",
//         key: "temperatureK",
//         width: 80,
//       },
//       {
//         title: "LM-80 test current",
//         dataIndex: "lm80TestCurrent",
//         key: "lm80TestCurrent",
//         width: 80,
//       },
//       {
//         title: <span>&alpha;</span>,
//         dataIndex: "alpha",
//         key: "alpha",
//         width: 80,
//       },
//       {
//         title: "B",
//         dataIndex: "b",
//         key: "b",
//         width: 80,
//       },
//       {
//         title: "L76 (hours)",
//         dataIndex: "hours",
//         key: "hours",
//         width: 80,
//       },
//       {
//         title: "#DUT",
//         dataIndex: "dut",
//         key: "dut",
//         width: 70,
//       },
//       {
//         title: "#Failures",
//         dataIndex: "failures",
//         key: "failures",
//         width: 95,
//       },
//     ],
//   },
//   {
//     title: "Interpolation",
//     dataIndex: "interpolation",
//     key: "interpolation",
//     children: [
//       {
//         title: "Current",
//         dataIndex: "current",
//         key: "current",
//         children: [
//           {
//             title: <span>&alpha;</span>,
//             dataIndex: "currentAlpha",
//             key: "currentAlpha",
//             width: 80,
//           },
//           {
//             title: "B",
//             dataIndex: "currentB",
//             key: "currentB",
//             width: 80,
//           },
//           {
//             title: "L76 (hours)",
//             dataIndex: "currentHours",
//             key: "currentHours",
//             width: 80,
//           },
//         ],
//       },
//       {
//         title: "Temperature",
//         dataIndex: "temperature",
//         key: "temperature",
//         children: [
//           {
//             title: <span>&alpha;</span>,
//             dataIndex: "temperatureAlpha",
//             key: "temperatureAlpha",
//             width: 80,
//           },
//           {
//             title: "B",
//             dataIndex: "temperatureB",
//             key: "temperatureB",
//             width: 80,
//           },
//           {
//             title: "L76 (hours)",
//             dataIndex: "temperatureHours",
//             key: "temperatureHours",
//             width: 80,
//           },
//         ],
//       },
//     ],
//   },
// ];

// const memoisedDataSource = useMemo(() => {
//   let dataSource = [];
//   const { data, current, temperature } = reportTable1Data || {};

//   if (reportTable1Data) {
//     for (let i = 0; i < 5; i++) {
//       dataSource.push({
//         key: i,
//         parameter: Array.isArray(data.parameter[i]) ? (
//           `${data.parameter[i][0]}°C, ${data.parameter[i][1]}mA`
//         ) : (
//           <span>{data.parameter[i]}</span>
//         ),
//         temperatureC: data.temperatureC[i]
//           ? `${data.temperatureC[i]}°C`
//           : `${interpolationParameters.inSituTemperature}°C`,
//         temperatureK: data.temperatureK[i]
//           ? `${data.temperatureK[i]}K`
//           : `${parseInt(interpolationParameters.inSituTemperature) + 273}K`,
//         lm80TestCurrent: data.lm80TestCurrent[i]
//           ? `${data.lm80TestCurrent[i]}K`
//           : `${interpolationParameters.inSituCurrent}mA`,
//         alpha: data.alpha[i].toExponential(3),
//         b: data.B[i].toFixed(4),
//         hours: `> ${data.hours[i]}`,
//         dut: data.dut[i] ? data.dut[i] : "-",
//         failures: data.failures[i],
//         currentAlpha: current.alpha[i]
//           ? current.alpha[i].toExponential(3)
//           : "-",
//         currentB: current.B[i] ? current.B[i].toFixed(4) : "-",
//         currentHours: current.hours[i] ? `> ${current.hours[i]}` : "-",
//         temperatureAlpha: temperature.alpha[i]
//           ? temperature.alpha[i].toExponential(3)
//           : "-",
//         temperatureB: temperature.B[i] ? temperature.B[i].toFixed(4) : "-",
//         temperatureHours: temperature.hours[i]
//           ? `> ${temperature.hours[i]}`
//           : "-",
//       });
//     }
//   }
//   return dataSource;
// }, [reportTable1Data, interpolationParameters]);
