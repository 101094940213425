/** @jsxImportSource @emotion/react */
import { keyframes, css } from "@emotion/react";

export const LoadingScreen = ({ show }) => {
  if (show) {
    return (
      <svg viewBox="0 0 70 70" css={spinner}>
        <path
          d="M35 0a4 4 0 0 1 0 8A29 29 0 0 0 35 66A33 33 0 0 1 35 0"
          fill="currentColor"
        />
      </svg>
    );
  }
  return null;
};

const spin = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const spinner = css`
  color: #d77600;
  margin: 8rem auto;
  width: 100%;
  height: 70px;
  animation: ${spin} 1.5s linear infinite;
  opacity: 0.8;
`;
