export const getBaseRequestConfig = () => {
  const config = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return config;
};

export const postBaseRequestConfig = (body, overriddenOptions = {}) => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  return Object.assign(config, overriddenOptions);
};
