import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true, errorMessage: error };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary">
          <h1>Something went wrong.</h1>
          <Button type="primary">
            <a
              href={`mailto:Michael.Royer@pnnl.gov;kaushik.thallapally@pnnl.gov?subject=Bug report-TM21 Calculator&body=Hi! I have encountered this bug. Please find the error log below.%0A%0A${this.state.errorMessage}`}
            >
              Report Bug
            </a>
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.object,
};
