import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "antd/dist/antd.css";
import "./styles/main.scss";

import Router from "./router";

import store from "./store";
import { ConfigProvider } from "antd";
import "dotenv/config";

ConfigProvider.config({
  theme: {
    primaryColor: "#d6781e",
  },
});
// const history = createBrowserHistory();
// Set initial state for application
// const initialState = {};
// const store = configureStore(history, initialState);

window.onbeforeunload = function (e) {
  e = e || window.event;

  // For IE and Firefox prior to version 4
  if (e) {
    e.returnValue = "Changes you made may not be saved.";
  }

  // For Safari
  return "Changes you made may not be saved.";
};

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
