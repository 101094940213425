import { postBaseRequestConfig } from "./base-request-config";

function calculateInterpolation(
  fluxData,
  interpolationData,
  reportedValues,
  calculationType
) {
  // const endpoint = "http://localhost:8080/api/interpolation";
  const endpoint = "https://tm21.pnnl.gov/api/interpolation";
  //authenticatedFetch will endpoint and config as an argument and will log the user out of session if the user is unauthorized
  return fetch(
    endpoint,
    postBaseRequestConfig({
      fluxData,
      interpolationData,
      reportedValues,
      calculationType,
    })
  ).then((response) => response.json());
}

const api = { calculateInterpolation };
export default api;
