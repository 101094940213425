/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Tooltip } from "antd";
import {
  CalculatorFilled,
  ReadFilled,
  FundFilled,
  MenuOutlined,
} from "@ant-design/icons";

// import { NavLink } from "react-router-dom";
import { setResultsView } from "redux/actions";
import { pageTypeEnum } from "redux/reducers";

import Tippy from "@tippyjs/react/headless";
import "tippy.js/dist/tippy.css";

// import { LoadingScreen } from "components/loadingScreen";

import "./index.scss";

// const NavigationItems = [
//   {
//     key: 1,
//     label: "Data Input",
//     value: "Data Input",
//     toPath: "/calculator",
//     pageType: pageTypeEnum.DataInput,
//     icon: <CalculatorFilled />,
//   },
//   {
//     key: 2,
//     label: "Reports",
//     value: "Reports",
//     toPath: "/report",
//     pageType: pageTypeEnum.Report,
//     icon: <FundFilled />,
//   },
//   {
//     key: 3,
//     label: "Work Space",
//     value: "Work Space",
//     toPath: "/interpolation",
//     pageType: pageTypeEnum.WorkSpace,
//     icon: <ReadFilled />,
//   },
// ];

const Navigation = () => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.dataReducers);
  const {
    pageType,
    interpolationPlotData,
    interpolationTableData,
    reportTable1Data,
    reportTable2Data,
    interpolationParameters,
    reportedValues,
    formattedFluxData,
  } = state;

  //   const showGlobalSpinner = useSelector(
  //     (state) => state.dataReducers.showGlobalSpinner
  //   );

  // const [currentPage, setCurrentPage] = useState(location.pathname);
  const [screenWidth, setScreenWidth] = useState(0);

  // useEffect(() => {
  //   setCurrentPage(location.pathname);
  // }, [location]);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const NavItems = () => {
    const ParentWrapper = screenWidth >= 992 ? "div" : "ul";
    const ChildWrapper = screenWidth >= 992 ? "div" : "li";
    return (
      <ParentWrapper
        css={screenWidth >= 992 && AppNavContainer}
        className={screenWidth < 992 && "small-screen-navigation"}
      >
        <ChildWrapper
          className="nav-item"
          onClick={() => dispatch(setResultsView(pageTypeEnum.DataInput))}
          css={AppNavItemStyle(pageType === pageTypeEnum.DataInput, false)}
        >
          <CalculatorFilled />
          Data Input
        </ChildWrapper>
        <Tooltip
          key="reports"
          title="Input data and hit go to reports to enable these navigation items"
          trigger={
            !interpolationParameters &&
            !interpolationPlotData &&
            !interpolationTableData &&
            !formattedFluxData
              ? ["hover"]
              : []
          }
        >
          <ChildWrapper
            className="nav-item"
            onClick={() =>
              (interpolationParameters ||
                interpolationPlotData ||
                interpolationTableData ||
                formattedFluxData) &&
              dispatch(setResultsView(pageTypeEnum.Report))
            }
            css={AppNavItemStyle(
              pageType === pageTypeEnum.Report,
              !interpolationParameters &&
                !interpolationPlotData &&
                !interpolationTableData &&
                !formattedFluxData
            )}
          >
            <FundFilled />
            Report
          </ChildWrapper>
        </Tooltip>
        <Tooltip
          key="work-space"
          title="Input data and hit go to reports to enable these navigation items"
          trigger={
            !reportedValues &&
            !reportTable1Data &&
            !reportTable2Data &&
            !formattedFluxData
              ? ["hover"]
              : []
          }
        >
          <ChildWrapper
            className="nav-item"
            onClick={() =>
              (reportedValues ||
                reportTable1Data ||
                reportTable2Data ||
                formattedFluxData) &&
              dispatch(setResultsView(pageTypeEnum.WorkSpace))
            }
            css={AppNavItemStyle(
              pageType === pageTypeEnum.WorkSpace,
              !reportedValues &&
                !reportTable1Data &&
                !reportTable2Data &&
                !formattedFluxData
            )}
          >
            <ReadFilled />
            Work Space
          </ChildWrapper>
        </Tooltip>
      </ParentWrapper>
    );
  };

  return (
    <div css={AppHeaderStyle}>
      <Row css={headerRowStyles}>
        <Col span={12} css={AppTitleColumn}>
          <div css={AppTitleStyle} to="/">
            {/* <img
              src={`${process.env.PUBLIC_URL}/images/ies-logo.jpg`}
              alt="IES Logo"
            /> */}
            {screenWidth < 1065 ? (
              <h1>
                ANSI/IES TM-21 Calculator
              </h1>
            ) : (
              <h1>
                ANSI/IES TM-21 Calculator
              </h1>
            )}
            {/* <LoadingScreen show={showGlobalSpinner === "globalSpin"} /> */}
          </div>
        </Col>
        <Col span={12}>
          {screenWidth >= 992 ? (
            <NavItems />
          ) : (
            <div className="tippy-nav-item">
              <Tippy
                render={(props) => <NavItems />}
                offset={[0, 0]}
                interactive
                placement="bottom"
                trigger="mouseenter focus click"
              >
                <MenuOutlined css={BurgerIcon} />
              </Tippy>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Navigation;

const AppTitleColumn = css`
  display: flex;

  svg {
    width: 4rem;
    height: auto;
    margin: 0 1rem;
  }
`;

const AppHeaderStyle = css`
  padding: 0 10px;
  background-color: #fff;
  overflow: hidden;

  sup {
    font-size: 14px;
    top: -18px;
  }
`;

const AppTitleStyle = css`
  display: flex;
  cursor: pointer;
  text-decoration: none !important;
  width: fit-content;

  h2,
  h3 {
    margin: 18px 10px;
  }
  img {
    width: 50px;
    height: 55px;
    padding: 15px 0px 0px 10px;
  }
`;

const AppNavContainer = css`
  display: flex;
  justify-content: flex-end;

  .nav-item {
    margin: 0 2rem;
  }

  a {
    margin: 0 2rem;
  }

  @media (max-width: 1056px) {
    justify-content: space-evenly;
    a {
      margin: 0;
    }
  }
`;

const AppNavItemStyle = (isActive, shouldDisable) => css`
  font-size: 18px;
  cursor: pointer;
  color: #47494c !important;
  text-decoration: none !important;
  //margin: 0 2rem;

  span {
    margin-right: 6px;
    font-size: 22px;
    border: none;
    color: #47494c;
  }

  :hover,
  :focus {
    border-bottom: 6px solid #d6781e;
  }

  ${shouldDisable &&
  css`
    cursor: not-allowed;
    opacity: 0.7;

    :hover,
    :focus {
      border-bottom: none;
    }
  `}

  border-bottom: ${isActive ? "6px solid #d6781e" : ""};
`;

const BurgerIcon = css`
  font-size: 2.5rem !important;
`;

const headerRowStyles = css`
  height: 4.5rem;
`;
