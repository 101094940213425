import {
  SET_FLUX_DATA,
  SET_FORMATTED_FLUX_DATA,
  SET_DATA_INPUT_PARAMETERS,
  SET_REPORTED_VALUES,
  SET_INTERPOLATION_PARAMETERS,
  CALCULATE_INTERPOLATION,
  SET_INTERPOLATION_CHANGED,
  RESET_ALL,
  IMPORT_JSON_DATA,
  CLEAR_PAGES,
  SET_PAGE_TYPE,
  SET_REPORT_METADATA,
} from "./types";

export const setDataInputParameters = (dataInputParameters) => ({
  type: SET_DATA_INPUT_PARAMETERS,
  payload: { dataInputParameters },
});

export const setFluxData = (fluxData) => ({
  type: SET_FLUX_DATA,
  payload: { fluxData },
});

export const setFormattedFluxData = (formattedFluxData) => ({
  type: SET_FORMATTED_FLUX_DATA,
  payload: { formattedFluxData },
});

export const setReportedData = (reportedValues) => ({
  type: SET_REPORTED_VALUES,
  payload: { reportedValues },
});

export const setReportMetadata = (reportMetadata) => ({
  type: SET_REPORT_METADATA,
  payload: { reportMetadata },
});

export const setInterpolationParameters = (interpolationParameters) => ({
  type: SET_INTERPOLATION_PARAMETERS,
  payload: { interpolationParameters },
});

export const calculateInterpolation = (
  fluxData,
  interpolationData,
  reportedValues,
  calculationType
) => ({
  type: CALCULATE_INTERPOLATION.REQUESTED,
  payload: { fluxData, interpolationData, reportedValues, calculationType },
});

export const setInterpolationChanged = () => ({
  type: SET_INTERPOLATION_CHANGED,
});

export const resetAll = () => ({
  type: RESET_ALL,
});

export const importJsonData = (jsonData) => ({
  type: IMPORT_JSON_DATA,
  payload: { jsonData },
});

export const clearPages = (isInterpolation) => ({
  type: CLEAR_PAGES,
  payload: { isInterpolation },
});

export const setResultsView = (view) => ({
  type: SET_PAGE_TYPE,
  payload: { view },
});
