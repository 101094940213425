import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";

import { useSelector } from "react-redux";

const InterpolationPlot = () => {
  const interpolationParameters = useSelector(
    (state) => state.dataReducers.interpolationParameters
  );
  const formattedFluxData = useSelector(
    (state) => state.dataReducers.formattedFluxData
  );
  const interpolationPlotData = useSelector(
    (state) => state.dataReducers.interpolationPlotData
  );

  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return function cleanup() {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  useEffect(() => {
    if (formattedFluxData) {
      const xAxis = Object.values(formattedFluxData).map(
        (fluxData) => fluxData.testData.driveCurrent
      );
      const yAxis = Object.values(formattedFluxData).map(
        (fluxData) => fluxData.testData.caseTemperature
      );
      if (interpolationParameters) {
        xAxis.push(parseInt(interpolationParameters.inSituCurrent));
        yAxis.push(parseInt(interpolationParameters.inSituTemperature));
      }
      setXAxis(xAxis);
      setYAxis(yAxis);
    }
  }, [formattedFluxData, interpolationParameters]);

  const getWidth = () => {
    if (screenWidth > 1356) {
      return 800;
    } else if (screenWidth > 1290) {
      return 760;
    } else if (screenWidth < 1290 && screenWidth > 910) {
      return 700;
    } else if (screenWidth < 910 && screenWidth > 805) {
      return 600;
    } else if (screenWidth < 805 && screenWidth > 705) {
      return 500;
    } else if (screenWidth < 705 && screenWidth > 605) {
      return 400;
    } else if (screenWidth < 605) {
      return 300;
    }
  };

  if (interpolationPlotData) {
    return (
      <Plot
        data={[
          // IES LM-80 test conditions
          {
            x: xAxis,
            y: yAxis,
            type: "scatter",
            mode: "markers",
            marker: { color: "black", symbol: "diamond" },
            name: "IES LM-80 test conditions",
          },
          // Current Interpolation
          {
            x: [
              interpolationPlotData.currentInterpolation[0][1],
              interpolationPlotData.currentInterpolation[1][1],
            ], //[500, 700],
            y: [
              interpolationPlotData.currentInterpolation[0][0],
              interpolationPlotData.currentInterpolation[1][0],
            ], //[85, 85],
            type: "scatter",
            mode: "lines",
            marker: { color: "red" },
            line: {
              dash: "dot",
              width: 4,
            },
            name: "Current Interpolation",
          },
          {
            x: [
              interpolationPlotData.currentInterpolation[2][1],
              interpolationPlotData.currentInterpolation[3][1],
            ], //[500, 700],
            y: [
              interpolationPlotData.currentInterpolation[2][0],
              interpolationPlotData.currentInterpolation[2][0],
            ], //[105, 105],
            type: "scatter",
            mode: "lines",
            marker: { color: "red" },
            line: {
              dash: "dot",
              width: 4,
            },
            showlegend: false,
            name: "Current Interpolation",
          },
          {
            x: [
              interpolationPlotData.currentInterpolation[0][1],
              interpolationPlotData.currentInterpolation[1][1],
              interpolationPlotData.currentInterpolation[2][1],
              interpolationPlotData.currentInterpolation[3][1],
            ], //[500, 700, 500, 700],
            y: [
              interpolationPlotData.currentInterpolation[0][0],
              interpolationPlotData.currentInterpolation[1][0],
              interpolationPlotData.currentInterpolation[2][0],
              interpolationPlotData.currentInterpolation[3][0],
            ], //[85, 85, 105, 105],
            type: "scatter",
            mode: "markers",
            marker: {
              color: "transparent",
              size: 15,
              line: {
                color: "red",
                width: 2,
              },
            },
            name: "Current Interpolation",
          },
          // Temperature Interpolation
          {
            x: [
              interpolationParameters.inSituCurrent,
              interpolationParameters.inSituCurrent,
            ], //[585,585]
            y: [
              interpolationPlotData.temperatureInterpolation[0],
              interpolationPlotData.temperatureInterpolation[1],
            ], //[85, 105],
            type: "scatter",
            mode: "lines",
            marker: { color: "blue" },
            line: {
              dash: "dot",
              width: 4,
            },
            name: "Temperature Interpolation",
          },
          {
            x: [
              interpolationParameters.inSituCurrent,
              interpolationParameters.inSituCurrent,
            ], //[585,585]
            y: [
              interpolationPlotData.temperatureInterpolation[0],
              interpolationPlotData.temperatureInterpolation[1],
            ], //[85, 105],
            type: "scatter",
            mode: "markers",
            marker: {
              color: "blue",
              symbol: "x",
              size: 12,
            },
            name: "Temperature Interpolation",
          },
          // Evaluated Conditions
          {
            x: [interpolationParameters.inSituCurrent],
            y: [interpolationParameters.inSituTemperature],
            type: "scatter",
            mode: "markers",
            marker: {
              color: "transparent",
              size: 8,
              symbol: "diamond",
              line: {
                color: "green",
                width: 2,
              },
            },
            name: "Evaluated Conditions",
          },
        ]}
        layout={{
          width: getWidth(),
          height: 500,
          legend: { orientation: "h", y: 10 },
          margin: {
            //   l: 70,
            //   r: 10,
            //   b: 10,
            t: 10,
            pad: 4,
          },
          xaxis: {
            title: {
              text: "<b>Drive Current</b>",
              font: {
                size: 15,
              },
            },
            showline: true,
            tickmode: "linear",
            range: [Math.min(...xAxis) - 100, Math.max(...xAxis) + 100],
            tick0: Math.min(...xAxis) - 100,
            dtick: 200,
            ticksuffix: " mA",
            fixedrange: true,
            mirror: "ticks",
            linewidth: 2,
            linecolor: "black",
          },
          yaxis: {
            title: {
              text: "<b>In-Situ Temperature<br /> </b>",
              font: {
                size: 15,
              },
            },
            showline: true,
            tickmode: "linear",
            range: [Math.min(...yAxis) - 30, Math.max(...yAxis) + 30],
            tick0: Math.min(...yAxis) - 30,
            dtick: 30,
            ticksuffix: " °C",
            fixedrange: true,
            mirror: "ticks",
            linewidth: 2,
            linecolor: "black",
          },
        }}
        config={{
          displaylogo: false,
          // modeBarButtons: ["toImage"],
          // responsive: true,
        }}
      />
    );
  } else {
    return (
      <Plot
        data={[
          // IES LM-80 test conditions
          {
            x: xAxis,
            y: yAxis,
            type: "scatter",
            mode: "markers",
            marker: { color: "black", symbol: "diamond" },
            name: "IES LM-80 test conditions",
          },
        ]}
        layout={{
          width: getWidth(),
          height: 500,
          legend: { orientation: "h", y: 10 },
          margin: {
            t: 10,
            pad: 4,
          },
          xaxis: {
            title: {
              text: "<b>Drive Current</b>",
              font: {
                size: 15,
              },
            },
            showline: true,
            tickmode: "array",
            range: [Math.min(...xAxis) - 100, Math.max(...xAxis) + 100],
            tick0: Math.min(...xAxis) - 100,
            ticktext: ["200 mA", "425 mA", "650 mA", "875 mA", "1100 mA"],
            tickvals: [200, 425, 650, 875, 1100],
            fixedrange: true,
            mirror: "ticks",
            linewidth: 2,
            linecolor: "black",
          },
          yaxis: {
            title: {
              text: "<b>In-Situ Temperature<br /> </b>",
              font: {
                size: 15,
              },
            },
            showline: true,
            range: [Math.min(...yAxis) - 30, Math.max(...yAxis) + 30],
            tick0: Math.min(...yAxis) - 30,
            ticktext: ["20 °C", "50 °C", "80 °C", "110 °C", "140 °C"],
            tickvals: [20, 50, 80, 110, 140],
            fixedrange: true,
            mirror: "ticks",
            linewidth: 2,
            linecolor: "black",
          },
        }}
        config={{
          displaylogo: false,
        }}
      />
    );
  }
};

export default InterpolationPlot;
