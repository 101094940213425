import React from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
import { Layout } from "antd";

import { ToastContainer } from "react-toastify";

import Navigation from "../../components/navigation";
import AppFooter from "../../components/footer";

// import { setGlobalToast } from "redux/actions";

const { Header, Footer, Content } = Layout;

const Landing = (props) => {
  //   const dispatch = useDispatch();

  //   const globalToast = useSelector(
  //     (state) => state.dataReducers.showGlobalToast
  //   );

  //   useEffect(() => {
  //     if (globalToast === "success") {
  //       toast.success(
  //         <div>
  //           <h3>Success</h3>{" "}
  //           <p>
  //             Successfully upload excel to the User Library.{" "}
  //             <Link to="/user-library">Click here</Link> for library.
  //           </p>
  //         </div>,
  //         {
  //           toastId: "global-toast-success",
  //         }
  //       );
  //     }
  //     if (globalToast === "fail") {
  //       toast.error(
  //         <div>
  //           <h3>Error</h3>{" "}
  //           <p>Something went wrong. Please check the input excel file again.</p>
  //         </div>,
  //         {
  //           toastId: "global-toast-fail",
  //         }
  //       );
  //     }
  //     if (globalToast === "info") {
  //       toast.info(
  //         <div>
  //           {/* <h3>Error</h3>{' '} */}
  //           <p>
  //             The tool will let you know once the upload is done. You can go ahead
  //             and access the site.
  //           </p>
  //         </div>,
  //         {
  //           toastId: "global-toast-info",
  //         }
  //       );
  //     }
  //     dispatch(setGlobalToast(false));
  //   }, [globalToast]);

  return (
    <Layout>
      <Header>
        <Navigation />
      </Header>
      <Content>
        {props.children}
        <ToastContainer />
      </Content>
      <Footer>
        <AppFooter />
      </Footer>
    </Layout>
  );
};

export default Landing;
