/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { Table } from "antd";

const ReportTable2 = () => {
  const reportTable2Data = useSelector(
    (state) => state.dataReducers.reportTable2Data
  );
  const reportedValues = useSelector(
    (state) => state.dataReducers.reportedValues
  );
  const interpolationRawOutput = useSelector(
    (state) => state.dataReducers.interpolationRawOutput
  );

  const columns = [
    {
      title: "Temp (°C)",
      dataIndex: "temperature",
      key: "temperature",
      width: 80,
    },
    {
      title: "Current (mA)",
      dataIndex: "current",
      key: "current",
      width: 80,
    },
    {
      title: "DUT",
      dataIndex: "dut",
      key: "dut",
      children: [
        {
          title: "Initial",
          dataIndex: "initial",
          key: "initial",
          width: 70,
        },
        {
          title: "Failures",
          dataIndex: "failures",
          key: "failures",
          width: 85,
        },
      ],
    },
    {
      title: "Test Duration (hrs)",
      dataIndex: "testDuration",
      key: "testDuration",
      width: 90,
    },
    {
      title: "Test Data for Projection",
      dataIndex: "testDataProjection",
      key: "testDataProjection",
      children: [
        {
          title: "Start Hours",
          dataIndex: "startHours",
          key: "startHours",
          width: 80,
        },
        {
          title: "Stop Hours",
          dataIndex: "stopHours",
          key: "stopHours",
          width: 80,
        },
      ],
    },
    {
      title: "Alpha",
      dataIndex: "alpha",
      key: "alpha",
      width: 80,
    },
    {
      title: "B",
      dataIndex: "b",
      key: "b",
      width: 80,
    },
    {
      title: `L${reportedValues?.fluxMaintenanceLevel}`,
      dataIndex: "l80",
      key: "l80",
      width: 80,
    },
  ];

  const memoisedDataSource = useMemo(() => {
    let dataSource = [];
    const {
      current,
      temperature,
      dut,
      testDuration,
      testDataForProjection,
      alpha,
      B,
      l80,
    } = reportTable2Data || {};

    if (reportTable2Data) {
      const stringifiedCurrentInterpolation =
        interpolationRawOutput.currentInterpolation.map((values) =>
          JSON.stringify(values)
        );
      current.forEach((item, i) => {
        const tempAndCurrent = [temperature[i], item];

        if (
          stringifiedCurrentInterpolation.includes(
            JSON.stringify(tempAndCurrent)
          )
        ) {
          dataSource.push({
            key: i,
            temperature: `${temperature[i]}`,
            current: `${item}`,
            initial: dut.initial[i],
            failures: dut.failures[i],
            testDuration: `${testDuration[i].toLocaleString()}`,
            startHours: `${testDataForProjection.startHours[
              i
            ].toLocaleString()}`,
            stopHours: `${testDataForProjection.stopHours[i].toLocaleString()}`,
            alpha: alpha[i].toExponential(3),
            b: B[i].toFixed(4),
            l80: l80[i],
          });
        }
      });
    }
    return dataSource;
  }, [reportTable2Data, interpolationRawOutput.currentInterpolation]);

  return (
    <Table
      css={reportTable2Styles}
      //   scroll={{ x: 800, y: 600 }}
      columns={columns}
      dataSource={memoisedDataSource}
      bordered
      pagination={false}
    />
  );
};

export default ReportTable2;

const reportTable2Styles = css`
  margin-bottom: 2rem;

  .ant-table-header > table > thead > tr > th {
    font-weight: bold;
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th {
    font-weight: bold;
  }

  .ant-table-tbody > tr > td {
    padding: 5px 5px;
    text-align: center;
  }
`;
