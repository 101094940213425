/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import InterpolationTable from "components/interpolationTable";
import InterpolationPlot from "components/interpolationPlot";
import { LoadingScreen } from "components/loadingScreen";

import { clearPages } from "redux/actions";

// import { ToastContainer } from "react-toastify";

import { Card, Button, Divider, Modal } from "antd";

export const WorkSpace = () => {
  const dispatch = useDispatch();

  const interpolationParameters = useSelector(
    (state) => state.dataReducers.interpolationParameters
  );
  const interpolationTableData = useSelector(
    (state) => state.dataReducers.interpolationTableData
  );
  const interpolationPlotData = useSelector(
    (state) => state.dataReducers.interpolationPlotData
  );
  const interpolationError = useSelector(
    (state) => state.dataReducers.interpolationError
  );

  const [clearInterpolationPageModal, setClearInterpolationPageModal] =
    useState(false);

  return (
    <>
      <Modal
        title="Clear interpolation page"
        visible={clearInterpolationPageModal}
        onCancel={() => setClearInterpolationPageModal(false)}
        footer={
          <>
            <Button
              onClick={() => {
                setClearInterpolationPageModal(false);
              }}
            >
              No
            </Button>
            <Button
              type="primary"
              danger
              ghost
              onClick={() => {
                dispatch(clearPages(true));
                setClearInterpolationPageModal(false);
              }}
            >
              Yes
            </Button>
          </>
        }
      >
        Are you sure you want to clear the interpolation page? This will also
        clear the report page.
      </Modal>
      {/* {!formattedFluxData && (
        <div css={noDataScreenStyles}>
          {" "}
          Enter flux data in data input page to perform interpolation
        </div>
      )} */}
      {/* <ToastContainer /> */}
      <Card>
        <div css={buttonContainerStyles}>
          {interpolationParameters &&
            interpolationTableData &&
            interpolationPlotData && (
              <Button
                style={{ marginRight: "1rem" }}
                className="button"
                type="primary"
                danger
                onClick={() => setClearInterpolationPageModal(true)}
              >
                Clear
              </Button>
            )}
          {/* <Button
            type="primary"
            disabled={!interpolationPlotData || !interpolationTableData}
          >
            Go to Report
          </Button> */}
        </div>
        <Divider />
        <div css={loadingScreenStyles}>
          <LoadingScreen
            show={
              (!interpolationPlotData || !interpolationTableData) &&
              interpolationParameters &&
              !interpolationError
            }
          />
        </div>
        <div
          css={noCalculationsStyles(
            (!interpolationPlotData || !interpolationTableData) &&
              interpolationParameters &&
              !interpolationError
          )}
        >
          <InterpolationPlot />
          <InterpolationTable />
        </div>
      </Card>
    </>
  );
};

// const noDataScreenStyles = css`
//   margin: 10% 35%;
//   position: absolute;
//   z-index: 1;
//   width: 35rem;
//   font-size: 20px;
//   font-weight: bold;
//   color: #d6781e;
// `;

// const noDataMainContentStyles = (noData) => css`
//   ${noData
//     ? css`
//         opacity: 0.2;
//         pointer-events: none;
//       `
//     : css``}

//   .col-left {
//     @media only screen and (max-width: 1200px) {
//       width: 100%;
//       margin-bottom: 1rem;
//     }
//     width: 24%;
//   }

//   .col-right {
//     @media only screen and (max-width: 1200px) {
//       width: 100%;
//     }
//     width: 70%;
//   }

//   .input-containter {
//     @media only screen and (max-width: 1200px) {
//       display: flex;
//     }
//   }

//   .ant-form-item {
//     @media only screen and (max-width: 1200px) {
//       padding: 10px;
//     }
//   }
// `;

const loadingScreenStyles = css`
  position: absolute;
  top: 15%;
  right: 47%;
  z-index: 1;
`;

const noCalculationsStyles = (noCalculation) => css`
  text-align: center;

  ${noCalculation
    ? css`
        opacity: 0.2;
      `
    : css``}
`;

const buttonContainerStyles = css`
  float: right;
  margin-bottom: 1rem;
`;
